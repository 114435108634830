<template>
    <div class="personalizaAP3">
        <div class="container py-4">
            <div class="row py-5">
                <div class="col-2 d-none d-lg-block"></div>
                <div class="col-lg-8 col-12">
                    <div>
                        <img src="../assets/img/pesa-ap3.png" alt="" width="150" class="d-inline mb-5">
                    </div>
                    <img src="../assets/img/line-big-cta-ap3.png" alt="" width="100%" class="d-inline mb-2">
                    <h1 class="h1Ap3 text-white">
                        PERSONALIZA <strong class="fontGold">TU PEDIDO</strong>
                    </h1>
                    <img src="../assets/img/line-big-cta-ap3.png" alt="" width="100%" class="d-inline mb-5">
                    <p class="text-white subAp3 mb-5">¿No sabes qué pedir?, te brindamos una<span class="fontGold"> asesoría en línea</span> con las mejores recomendaciones.</p>
                    <a href="/comprar-ya">
                        <button class="btn btn-outline-secondary primaryBtn" type="button" id="button-addon2">HAZ TU PEDIDO YA</button>
                    </a>
                </div>
                <div class="col-2 d-none d-lg-block"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PersonalizaCta',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
