<template>
    <div class="pb-3">
        <div class="container info-store-content ">
            <div class="row text-left py-3 border-top border-white pt-4">
                <div class="col-lg-3 col-12">
                    <p class="footerTitle mb-1">Sede principal</p>
                    <p class="text-white mt-3">
                        CRA 40 # 36 sur 30 - Envigado
                    </p>
                </div>
                <div class="col-lg-3 col-12">
                    <p class="footerTitle mb-1">Ap3 Mercados Guayabal</p>
                    <p class="text-white mt-3">
                        CR 52d # 75 aa sur 280 LC 129 - Itagüí <br/><br/>
                        Estamos dentro del supermercado Mercados Guayabal
                    </p>
                </div>
                <div class="col-lg-3 col-12">
                    <p class="footerTitle mb-1">Ap3 Central Mayorista</p>
                    <p class="text-white mt-3">
                        CL 85 # 48 01 bloque 24 local 155 - Itagüí <br/><br/>
                        Central mayorista de Antioquía
                    </p>
                </div>
                <div class="col-lg-3 col-12">
                    <p class="footerTitle mb-1">Ap3 Gran Fruver Envigado</p>
                    <p class="text-white mt-3">
                        CR 42 # 38 sur 50 - Envigado <br/><br/>
                        Estamos dentro del Grand Fruver de Envigado
                    </p>
                </div>
               
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'InfoStores',
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.info-store-content .footerTitle{
    font-size: 1.1rem;
}
.info-store-content .footerTitle + p{
        font-size: 11px;
}

</style>
