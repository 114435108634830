<template>
  <div class="categoria">
    <Menu></Menu>
    <div class="container mb-5 categorieView">
      <div class="row">
        <div class="col-12 pt-5 mb-5">
          <!-- <img v-if="category.image" :src="`${category.image}`" alt="" width="40%" class="d-inline mb-2">
                <img v-else src="../assets/img/line-big-cta-ap3.png" alt="" width="40%" class="d-inline mb-2"> -->
          <h1 class="h1Ap3 text-white" style="text-transform: uppercase">
            {{ category[0] ? category[0].name : "" }}
          </h1>
          <img
            src="../assets/img/line-big-cta-ap3.png"
            alt=""
            width="70%"
            class="d-inline mb-5"
          />
          <p class="text-white categoryDescription mb-5">
            {{ category[0]?category[0].description:"" }}
          </p>
        </div>
        <div class="col-12">
          <CategoriesBar></CategoriesBar>
        </div>
        <div class="col-lg-3 pr-3" v-if="category[0]">
          <Sidebar :data="category[0]"></Sidebar>
        </div>
        <div class="col-lg-9">
          <div class="row">
            <div class="col-12">
              <h4 class="h1Ap3 text-white text-uppercase mb-4 text-left">
                {{ subcategory || (category[0]?category[0].name:"") }}
              </h4>
            </div>
            <Loader
              v-if="loading"
              :loading="loading"
              style="margin: 0 auto"
            ></Loader>
            <ProductCard 
              v-else
              v-for="product in category[0].productos"
              :data="product"
            ></ProductCard>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <InfoStores></InfoStores>
    <Copyright></Copyright>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";
import Subscribe from "../components/Subscribe.vue";
import Copyright from "../components/Copyright.vue";
import WizardComp from "../components/WizardComp.vue";
import ProductCard from "../components/ProductCard.vue";
import SuperCategoria from "../components/SuperCategoria.vue";
import Sidebar from "../components/Sidebar.vue";
import CategoriesBar from "../components/CategoriesBar.vue";
import CategoryService from "../services/Categories";
import ProductService from "../services/Products";
import InfoStores from "../components/InfoStores.vue";
import { createNamespacedHelpers } from "vuex";
import Loader from "../components/Loader.vue";
import qs from "qs";

const { mapActions: productsMapActions, mapGetters: productsMapGetters } =
  createNamespacedHelpers("products");

export default {
  name: "Categoria",
  components: {
    Menu,
    Footer,
    Subscribe,
    Copyright,
    WizardComp,
    ProductCard,
    SuperCategoria,
    Sidebar,
    CategoriesBar,
    Loader,
    InfoStores,
  },
  data() {
    return {
      categoryName: "",
      category: "",
      loading: true,
    };
  },
  async mounted() {
    this.slug = this.$route.params.category;
    this.getProducts(this.slug);
    this.getCategory(this.slug);
    this.addMeta(this.category);
  },
  computed: {
    ...productsMapGetters(["subcategory", "products"]),
  },
  methods: {
    ...productsMapActions(["SET_SUBCATEGORY", "SET_PRODUCTS"]),
    async getProducts(category) {
      const query = qs.stringify({
        _where: [{ slug: category }],
      });
      const products = await ProductService.getProductsByQuery(query);

      this.SET_PRODUCTS(products.data);
      this.loading = false;
    },
    async getCategory(category) {
      const query = qs.stringify({
        _where: [{ slug: category }],
      });
      this.category = await CategoryService.getCategories(query);

      return this.category;
    },
    async addMeta(category) {
      window.document.title = `${category.name} | AP3`;

      const metaDescription = document.createElement("meta");
      metaDescription.setAttribute("name", "description");
      metaDescription.setAttribute("content", category.description);
      window.document
        .getElementsByTagName("head")[0]
        .appendChild(metaDescription);
      const metaImage = document.createElement("meta");
      metaImage.setAttribute("name", "image");
      let categoriaImg =
        category.image && typeof category.image === "object"
          ? category.image.url
          : category.image;

      metaImage.setAttribute("content", categoriaImg);
      window.document.getElementsByTagName("head")[0].appendChild(metaImage);
    },
  },
};
</script>
