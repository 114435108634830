<template>
    <div class="menuAP3">
        <!-- desktop version -->
        <div class="container d-none d-lg-block">
            <div class="row d-flex align-items-center py-3">
                <div class="col-2">
                    <div class="brandAP3 text-left border-right border-white">
                        <a href="/">
                            <img src="../assets/img/logo-ap3.png" alt="" width="110">
                        </a>
                    </div>
                </div>
                <div class="col-7 pl-5">
                    <div class="navAP3 navbar-expand-lg">
                        <div class="navbar-nav">
                            <router-link to="/" class="nav-link mr-4">HOME</router-link>
                            <!-- <router-link to="/comprar-ya" class="nav-link mr-4">COMPRA YA</router-link> -->
                            <router-link to="/productos" class="nav-link mr-4">PRODUCTOS</router-link>
                            <router-link to="/about" class="nav-link mr-4">SOBRE NOSOTROS</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-3 text-right">
                    <div class="cartAP3 navbar-expand-lg">
                        <div class="navbar-nav">
                            <div class="carrito d-flex align-items-center px-2 mr-4">
                                <a href="/carrito-de-compras">
                                    <p href="/carrito-de-compras" class="d-inline font-weight-bolder text-white mr-2 mb-0">
                                        CARRITO
                                    </p>
                                    <img src="../assets/img/shopping-cart-solid.svg" alt="" width="20" class="d-inline">
                                </a>
                            </div>
                            <a href="https://www.facebook.com/ap3carnesoficial" target="_blank" class="nav-link"><img src="../assets/img/facebook.svg" alt="" width="25" class="d-inline mr-4"></a>
                            <a href="https://www.instagram.com/ap3carnesoficial" target="_blank" class="nav-link"><img src="../assets/img/instagram.svg" alt="" width="25" class="d-inline"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end desktop version -->

        <!-- mobile version -->
        <div class="container navMobile d-lg-none borderRedBottom">
            <div class="row d-flex align-items-center py-3">
                <div class="col-12">
                    <b-navbar toggleable class="p-0 backTransparent">
                        <b-navbar-toggle target="navbar-toggle-collapse"> 
                            <template #default="{ expanded }">
                                
                                <span class="material-icons">menu</span>
                                <!-- <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                                <b-icon v-else icon="chevron-bar-down"></b-icon> -->
                            </template>
                        </b-navbar-toggle>
                        <b-navbar-brand href="#" class="p-0 m-0">
                            <div class="brandAP3 text-left">
                                <img src="../assets/img/logo-ap3.png" alt="" width="90">
                                
                            </div>
                        </b-navbar-brand>
                        <div class="d-flex align-items-center px-2">
                            <a href="/carrito-de-compras">
                                <img src="../assets/img/shopping-cart-solid.svg" alt="" width="20" class="d-inline">
                            </a>
                        </div>

                        

                        <b-collapse id="navbar-toggle-collapse" is-nav>
                            <b-navbar-nav class="ml-auto py-5 navAp3M">
                                <router-link to="/" class="nav-link text-center py-2">HOME</router-link>
                                <!-- <router-link to="/categoria" class="nav-link text-center py-2">COMPRA YA</router-link> -->
                                <router-link to="/productos" class="nav-link text-center py-2">PRODUCTOS</router-link>
                                <router-link to="/about" class="nav-link mr-4">SOBRE NOSOTROS</router-link>
                            </b-navbar-nav>
                            <a href="https://www.facebook.com/ap3carnesoficial" target="_blank" class=""><img src="../assets/img/facebook.svg" alt="" width="25" class="d-inline mr-4"></a>
                            <a href="https://www.instagram.com/ap3carnesoficial" target="_blank" class=""><img src="../assets/img/instagram.svg" alt="" width="25" class="d-inline"></a>
                        </b-collapse>
                    </b-navbar>
                </div>
            </div>
        </div>
        <!-- end mobile version -->
    </div>
</template>

<script>
export default {
  name: 'Menu',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
