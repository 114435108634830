<template>
  <div class="about">
    <Menu></Menu>
    <div class="aboutHero pb-5">
      <div class="container py-5">
        <div class="row py-5">
          <div class="col-lg-6"></div>
          <div class="col-lg-6 position-relative">
            <img src="../assets/img/machine-icon.png" alt="" width="80" class="d-lg-inline mb-4 position-absolute machineIcon d-none d-lg-block">
            <img src="../assets/img/machine-icon.png" alt="" width="80" class="d-inline mb-4 position-absolute machineIconM d-lg-none">
            <div class="historia p-4 text-left">
              <div class="container">
                <h2 class="historiaTitle font-weight-bolder fontGold d-none d-lg-block">UNA HISTORIA DE <br><span class="text-white font-italic mr-3">4</span>GENERACIONES</h2>
                <h2 class="historiaTitleM font-weight-bolder fontGold d-lg-none mt-4 text-center">UNA HISTORIA DE <br><span class="text-white font-italic mr-3">4</span>GENERACIONES</h2>
                <img src="../assets/img/line-big-cta-ap3.png" alt="" width="100%" class="d-inline mb-4">
                <p class="text-white fontLibre font-italic">La historia de AP3 Carnes se remonta al año de 1930 en el municipio de Pácora - Caldas, donde nuestros padres iniciaron una carnicería tradicional, cortando con hacha sobre un tronco de madera y envolviendo sus productos en hoja de bijao.
                  <br><br>
Hoy la tercera generación de la Familia Aguirre ha introducido importantes cambios para tecnificar cada uno de los procesos, como modernos sistemas de empaque termo-formado para garantizar la correcta preservación de cada producto.</p>
              </div>
              <div class="fontGold subAp3 text-center mt-5 d-none d-lg-block conoceMas">
                <p
                  type="text"
                  @click="dialogVisible = true"
                  class="fontGold subAp3 text-center mt-5"
                >
                  CONOCE MÁS
                </p>
              </div>
              <el-dialog
                :visible.sync="dialogVisible"
                width="50%"
                :before-close="handleClose"
              >
                <div class="aboutModal px-5 py-5">
                  <h2 class="historiaTitle font-weight-bolder fontGold d-none d-lg-block text-center mt-5">UNA HISTORIA DE <br><span class="text-white font-italic mr-3">4</span>GENERACIONES</h2>
                  <h2 class="historiaTitleM font-weight-bolder fontGold d-lg-none mt-4 text-center">UNA HISTORIA DE <br><span class="text-white font-italic mr-3">4</span>GENERACIONES</h2>
                  <div class="row align-items-center mt-5">
                    <div class="col-4 text-right">
                      <img src="../assets/img/humberto-ap3.png" alt="" width="60%" class="d-inline mb-4">
                    </div>
                    <div class="col-6">
                      <p class="textUnWrap fontLibre fontGold font-weight-bold text-center">“Vamos a cambiar el concepto de la <br>carnicería tradicional” <br><br>
                          Humberto Aguirre Perez.
                      </p>
                    </div>
                  </div>
                  <p class="textUnWrap text-white fontLibre">Esta historia comenzó en el año 1930 en Pácora Caldas, cuando mis abuelos día a día se ganaban la vida en una carnicería de barrio. Mi padre aprendió el arte también y durante toda su vida dedicó
                    gran parte de su tiempo a montar varias, aquí, allá y en todos lados a donde íbamos pues a mi padre le gustaba explorar las diferentes regiones de Colombia.

                    De ese modo fué como mi hermano y yo aprendimos también esta increíble labor, la cual llenó nuestras vidas de mucha satisfacción y amor por la carne.

                    Por diferentes razones debí comenzar mi vida laboral a muy temprana edad, terminé el colegio con celeridad en un instituto de validación mientras trabajaba para sostener a mis hermanos y a mi madre.

                    No tuve la oportunidad de estudiar en una universidad, pero eso no me detuvo, con disciplina y muchas ganas de salir adelante pude crear a AP-3 CARNES.

                    Siempre pensé que el método de comercialización de la carne debía ser diferente, debíamos implementar estrategias de higiene para que el cliente se sintiera más cómodo al momento de comprarla. Creador y fundador de AP-3 Carnes
                  </p>
                  <p class="textUnWrap text-white fontLibre mt-5">Antes de crear AP-3 Carnes debí trabajar en muchas partes, en algunas me desempeñé como carnicero, en otras no, pero cada experiencia me ayudó a crear lo que quería.

                    En el año 1988 en Envigado Antioquia, abrí las puertas de esta gran familia, pero la verdad es que si comenzó siendo una carnicería tradicional y poco a poco fui cambiando varias cosas que no me gustaban.<br/>

                    Introduje en el mercado las balanzas electrónicas en Antioquia, uniforme mis empleados para que se vieran presentables, elimine desagradables olores que comúnmente tenían las carnicerías y hasta encontré la
                    manera de eliminar las moscas que a diario me atormentaban al verlas encima de la carne.<br/>

                    También capacité a mi personal para que fueran personas pulcras y carismáticas con el cliente, ya no era el típico carnicero gruñón que fumaba mientras manipulaba el producto.<br/>

                    Siempre he creído que la calidad comienza desde la selección de mis ganados y mis proveedores, por eso siempre he sido muy enfático y estricto en el tema, seguidamente considero que hay que prestarle atención a cada detalle, sin importar lo pequeño que sea, porque ahí radica la perfección y el diferencial de nuestra compañía.<br/>

                    Ahora mis hijos continúan con este legado, la cuarta generación de una gran familia, su labor es llegar a más personas no solo para que puedan llevar a su mesa productos de la mejor calidad si no también para generar más empleo y así dedicar la vida de AP-3 CARNES a la búsqueda del bienestar de los demás.<br/>
                  </p>
                  
                </div>
              </el-dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="quienesSomos py-5">
      <div class="container py-5">
        <div class="row">
          <div class="col-lg-5">
            <div class="row">
              <div class="col-6">
                <h1 class="h1Ap3 text-white text-left">¿QUIÉNES <strong class="fontGold">SOMOS?</strong></h1>
              </div>
              <div class="col-6">
                <img src="../assets/img/since-wizard.png" alt="" width="60%" class="d-inline">
              </div>
              <div class="col-12">
                <img src="../assets/img/line-big-cta-ap3.png" alt="" width="100%" class="d-inline mb-4">
              </div>
            </div>
            <p class="fontLibre text-white text-left font-italic">
              Somos una empresa dedicada a la producción y comercialización de productos cárnicos de la más alta calidad, altamente tecnificada y enfocada en desarrollar productos innovadores para que nuestros clientes vivan una experiencia memorable con nuestros productos, servicio y calidad.
            </p>
          </div>
          <div class="col-lg-7 py-5">
            <el-carousel :interval="4000" type="card" height="180px" arrow="always" indicator-position="none">
              <el-carousel-item v-for="item in 3" :key="item">
                <h3 class="medium">{{ item }}</h3>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
    <div class="nuestros py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-12">
            <div class="border border-white p-3">
              <img src="../assets/img/nuestra-familia.png" alt="" width="30%" class="d-inline mb-4">
              <h1 class="h1Ap3 text-white">NUESTRA <strong class="fontGold"><br>FAMILIA</strong></h1>
              <img src="../assets/img/line-big-cta-ap3.png" alt="" width="60%" class="d-inline">
              <p class="text-white fontLibre font-italic mt-4">
                Cada una de las personas que hacen parte de AP-3 CARNES, los cuales son un eslabón indispensable para poder cumplir el objetivo
                final que tenemos como empresa, juntos buscamos darle a nuestros clientes una experiencia unica. Gracias a una constante capacitación podemos lograr los resultados deseados.
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12">
            <div class="border border-white p-3">
              <img src="../assets/img/nuestros-productos.png" alt="" width="30%" class="d-inline mb-4">
              <h1 class="h1Ap3 text-white">NUESTROS <strong class="fontGold"><br>PRODUCTOS</strong></h1>
              <img src="../assets/img/line-big-cta-ap3.png" alt="" width="60%" class="d-inline">
              <p class="text-white fontLibre font-italic mt-4 pb-4">
                Todos nuestros productos están calificados con estrictos estándares de calidad desde que estan en finca hasta que es comercializado. A cada etapa se le hace un seguimiento riguroso que garantiza obtener un producto que cumpla con tus expectativas.
              </p>
            </div>
          </div>
          
        </div>
      </div>
    </div>
     <div class="">
      <el-carousel trigger="click" :interval="5000" height="450px" arrow="always" class="d-none d-lg-block">
        <el-carousel-item v-for="" :key="item">
          <div class="nuestrosBovinos">
            <div class="container text-left pt-5">
              <h1 class="h1Ap3 text-white text-left pt-5">NUESTROS <strong class="fontGold"><br>BOVINOS</strong></h1>
              <img src="../assets/img/line-big-cta-ap3.png" alt="" width="60%" class="d-inline">
              <p class="fontLibre text-white text-left mb-5">Nuestros bovinos son alimentados en pasturas naturales de nuestro país, son libres de
                  hormonas de crecimiento y toda su vida son cuidados por ganaderos responsables que aman el campo, sus fincas están certificadas por el ICA lo que garantiza las buenas practicas ganaderas. Seleccionamos novillos jóvenes de raza brahman que pesen aproximadamente 500 kg, con estas características obtenemos un producto de alta calidad.
              </p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item v-for="" :key="item">
          <div class="nuestrosPorcinos">
            <div class="container text-left pt-5">
              <h1 class="h1Ap3 text-white text-left pt-5">NUESTROS <strong class="fontGold"><br>PORCINOS</strong></h1>
              <img src="../assets/img/line-big-cta-ap3.png" alt="" width="60%" class="d-inline">
              <p class="fontLibre text-white text-left mb-5">Nuestros porcinos son cuidadosamente seleccionados, en ellos miramos que su genética sea de alta calidad (PIC), la granja y sus diferentes prácticas e instalaciones, pues estas deben ser estrictas con los estándares de higiene, bioseguridad y cuidado animal. 
Mantenemos comunicación directa con nuestros proveedores para garantizar que a diario se cumple con nuestros requerimientos. 
              </p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <el-carousel trigger="click" :interval="5000" height="550px" arrow="always" class="d-lg-none">
        <el-carousel-item v-for="" :key="item">
          <div class="nuestrosBovinos">
            <div class="container text-left pt-5">
              <h1 class="h1Ap3 text-white text-left pt-5">NUESTROS <strong class="fontGold"><br>BOVINOS</strong></h1>
              <img src="../assets/img/line-big-cta-ap3.png" alt="" width="60%" class="d-inline">
              <p class="fontLibre text-white text-left mb-5">Nuestros bovinos son alimentados en pasturas naturales de nuestro país, son libres de
                  hormonas de crecimiento y toda su vida son cuidados por ganaderos responsables que aman el campo, sus fincas están certificadas por el ICA lo que garantiza las buenas practicas ganaderas. Seleccionamos novillos jóvenes de raza brahman que pesen aproximadamente 500 kg, con estas características obtenemos un producto de alta calidad.
              </p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item v-for="" :key="item">
          <div class="nuestrosPorcinos">
            <div class="container text-left pt-5">
              <h1 class="h1Ap3 text-white text-left pt-5">NUESTROS <strong class="fontGold"><br>PORCINOS</strong></h1>
              <img src="../assets/img/line-big-cta-ap3.png" alt="" width="60%" class="d-inline">
              <p class="fontLibre text-white text-left mb-5">Nuestros porcinos son cuidadosamente seleccionados, en ellos miramos que su genética sea de alta calidad (PIC), la granja y sus diferentes prácticas e instalaciones, pues estas deben ser estrictas con los estándares de higiene, bioseguridad y cuidado animal. 
Mantenemos comunicación directa con nuestros proveedores para garantizar que a diario se cumple con nuestros requerimientos. 
              </p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="certification">
      <div class="container">
        <div class="row py-5">
          <div class="col border-right">
            <img src="../assets/img/logo-cafe.png" alt="" width="100%" class="d-inline">
          </div>
          <div class="col">
            <img src="../assets/img/ap3-bpm.png" alt="" width="100%" class="d-inline">
          </div>
          <div class="col">
            <img src="../assets/img/ap3-favorable.png" alt="" width="100%" class="d-inline">
          </div>
          <div class="col">
            <img src="../assets/img/ap3-ica.png" alt="" width="100%" class="d-inline">
          </div>
          <div class="col">
            <img src="../assets/img/ap3-invima.png" alt="" width="100%" class="d-inline">
          </div>
          <div class="col">
            <img src="../assets/img/ap3-sanitaria.png" alt="" width="100%" class="d-inline">
          </div>
        </div>
      </div>
    </div>
    <div class="aboutGallery">
      <img src="../assets/img/Nosotros_Ap3-gallery.jpg" alt="" width="100%" class="d-inline">
    </div>
    <Footer></Footer>
    <InfoStores></InfoStores>
    <Copyright></Copyright>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from '../components/Menu.vue'
import Footer from '../components/Footer.vue'
import Subscribe from '../components/Subscribe.vue'
import Copyright from '../components/Copyright.vue'
import InfoStores from "../components/InfoStores.vue";




export default {
  name: 'About',
  data () {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisibleM = false;
    },
  },
  components: {
    Menu,
    Footer,
    Subscribe,
    Copyright,
    InfoStores,
  }
}
</script>

<style>

  .aboutModal .textUnWrap{
    word-break: break-word;
  }

  .quienesSomos .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
    border-radius: 300px;
 
  }

  .quienesSomos .el-carousel__item {
    background-image:url("../assets/img/quienes-somos-01.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .quienesSomos .el-carousel__item:nth-child(2n) {
    background-image:url("../assets/img/quienes-somos-02.png");
    background-size: contain;
    background-repeat: no-repeat;
  }

    .quienesSomos .el-carousel__item:last-child {
    background-image:url("../assets/img/quienes-somos-03.png");
    background-size: contain;
    background-repeat: no-repeat;
  }


  .quienesSomos .el-carousel__mask {
    background: transparent !important;
  }

  .quienesSomos .el-icon-arrow-right::before {
    content: "\e6e0";
    color: #cebf7c;
    font-size: 30px;
  }

  .quienesSomos .el-icon-arrow-left::before {
    content: "\e6e0";
    color: #cebf7c;
    font-size: 30px;
  }

  @media screen and (max-width: 600px) {
    /* .aboutModal .titleUnWrap{
      word-break: break-word;
    }   */
  }
  

</style>
