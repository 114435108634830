import axios from "axios"
import configService from "../../configs/config"
import '../../utils/AxiosInterceptors';


const seoService = {}
seoService.getSeo = () => {
  return axios
    .get(`${configService.apiUrl}/seo`)
    .then(seo => seo.data)
    .catch(error => error)
}

seoService.createSeo = data => {
  return axios
    .post(`${configService.apiUrl}/seo`, data)
    .then(res => res.data)
    .catch(error => error)
}

seoService.updateSeo = (id, data) => {
  return axios
    .put(`${configService.apiUrl}/seo/${id}`, data)
    .then(res => res.data)
    .catch(error => error)
}

seoService.removeSeo = id => {
  return axios
    .delete(`${configService.apiUrl}/seo/${id}`)
    .then(res => res.data)
    .catch(error => error)
}

export default seoService