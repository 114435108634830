import axios from "axios"
import configService from "../../configs/config"
const token = window.localStorage.AUTH_TOKEN

const categoryService = {}
categoryService.getCategories = (query) => {
  return axios
    .get(`${configService.apiUrl}/categorias?${query}`)
    .then(categorias => categorias.data)
    .catch(error => error)
}

categoryService.getCategory = id => {
  return axios
    .get(`${configService.apiUrl}/categorias/${id}`)
    .then(res => res.data.pop())
    .catch(error => error)
}
///@deprecated
categoryService.getByParent = category => {
  return axios
    .post(`${configService.apiUrl}/categorias/byParent`, category)
    .then(res => res.data)
    .catch(error => error)
}
///@deprecated
categoryService.getByName = category => {
  return axios
    .post(`${configService.apiUrl}/categorias/byName`, category)
    .then(res => res.data.pop())
    .catch(error => error)
}
///@deprecated
categoryService.createCategory = product => {
  return axios
    .post(`${configService.apiUrl}/categorias/create`, product)
    .then(res => res.data)
    .catch(error => error)
}
///@deprecated
categoryService.updateCategory = (id, product) => {
  return axios
    .put(`${configService.apiUrl}/categorias/${id}`, product)
    .then(res => res.data)
    .catch(error => error)
}
///@deprecated
categoryService.removeCategory = id => {
  return axios
    .delete(`${configService.apiUrl}/categorias/${id}`)
    .then(res => res.data)
    .catch(error => error)
}

export default categoryService