<template>
  <div class="categoriesBar mb-5 pb-5">
    <div class="row">
      <div
        v-for="category in categories"
        class="col col-lg-2 d-flex align-items-center pl-4 p-2 mb-3 catContainer catActive mr-2 rounded-pill"
      >
        <div class="catImgCont rounded-circle mr-3">
          <img
            v-if="category.image"
            :src="category.image && typeof category.image === 'object' ? category.image.url: category.image "
            :alt="category.name"
            width="50"
            height="50"
            class="d-inline mb-5 catImg rounded-circle"
          />
          <img
            v-else
            src="../assets/img/AP31.jpg"
            alt=""
            width="50"
            height="50"
            class="d-inline mb-5 catImg rounded-circle"
          />
        </div>
        <div>
          <a style="cursor: pointer;" :href="`/categoria/${category.slug}`"
            ><small class="mb-0 text-white font-weight-bold">{{
              category.name
            }}</small></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import categoryService from '@/services/Categories'
  import ProductService from '../services/Products'
  import qs from 'qs'
  import { createNamespacedHelpers } from 'vuex'
  const {
    mapActions: productsMapActions,
    mapGetters: productsMapGetters,
  } = createNamespacedHelpers('products')

  export default {
    name: 'Sidebar',
    data() {
      return {
        categories: '',
        categoryName: '',
      }
    },
    mounted() {
      this.categoryName = this.$route.params.category
      this.getCategories()
    },
    computed: {
      ...productsMapGetters(['subcategory', 'products']),
    },
    methods: {
      ...productsMapActions(['SET_SUBCATEGORY', 'SET_PRODUCTS']),
      async getCategories() {
        const query = qs.stringify({})
        const categories = await categoryService.getCategories(query)

        this.categories = categories.filter(
          (category) => !category.parentCategory && category.parentCategory !== this.categoryName
        )
        this.loading = false
        return this.categories
      },
      async getProducts(category) {
        this.SET_SUBCATEGORY(category)
        const products = await ProductService.getByCategory({
          categories: [category],
        })
        this.SET_PRODUCTS(products.data)
      },
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
