<template>
    <div>
        <!-- desktop version -->
        <div class="tiendaCtaAP3 pb-3 py-5 d-none d-lg-block">
            <div class="container">
                <div class="row">
                    <div class="col-7">

                    </div>
                    <div class="col-5 text-left py-5">
                        <div>
                            <h4 class="text-white h4Ap3 mb-3">TENEMOS UNA SELECCIÓN<br> DE LAS MEJORES CARNES<br> PARA TI</h4>
                            <img src="../assets/img/line-cta-ap3.png" alt="" width="400" class="d-inline mb-4">
                        </div>
                        <a href="/comprar-ya">
                            <button class="btn btn-outline-secondary primaryBtn" type="button" id="button-addon2">ENTRA A LA TIENDA</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!-- desktop version -->

        <!-- mobile version -->
        <div class="tiendaCtaAP3M d-lg-none">
            <div class="container">
                <div class="row ">
                    <div class="col-12 text-center py-5">
                        <div>
                            <h5 class="text-white mb-3 h5Ap3M">DESDE 1930</h5>
                            <h4 class="text-white h4Ap3 mb-4 borderGold py-3">TENEMOS UNA SELECCIÓN<br> DE LAS MEJORES CARNES PARA TI</h4>
                        </div>
                        <button class="btn btn-outline-secondary primaryBtn" type="button" id="button-addon2">ENTRA A LA TIENDA</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- mobile version -->
    </div>
</template>

<script>
export default {
  name: 'TiendaCta',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
