<template>
  <div class="wizard">
    <Menu></Menu>
    <WizardComp :data="product" class="wizardView"></WizardComp>
    <Footer></Footer>
    <InfoStores></InfoStores>
    <Copyright></Copyright>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";
import Subscribe from "../components/Subscribe.vue";
import Copyright from "../components/Copyright.vue";
import WizardComp from "../components/WizardComp.vue";
import productService from "../services/Products";
import InfoStores from "../components/InfoStores.vue";

export default {
  name: "Wizard",
  components: {
    Menu,
    Footer,
    Subscribe,
    Copyright,
    WizardComp,
    InfoStores,
  },
  data() {
    return {
      id: "",
      product: "",
      meta: "",
      title: ""
    };
  },
  head: {
    title: function() {
      return {
        inner: this.title,
      };
    },
  },
  async mounted() {
    this.id = this.$route.params.id;
    // this.getProduct(this.id);
    const product = await this.getProduct(this.id);
    this.product = product;
    this.addMeta(product);
  },
  methods: {
    getAsyncData: function() {
      var self = this
      window.setTimeout(function() {
        self.title = "normailito"
        self.$emit('updateHead')
      },3000)
    },
    async getProduct(id) {
      return productService.getProduct(id);
    },
    async addMeta(product) {
      window.document.title = `${product.seoTitle} | AP3`;
      const metaDescription = document.createElement("meta");
      metaDescription.setAttribute("name", "description");
      metaDescription.setAttribute("content", product.metaDescription);
      window.document
        .getElementsByTagName("head")[0]
        .appendChild(metaDescription);
      const metaImage = document.createElement("meta");
      metaImage.setAttribute("name", "image");
      metaImage.setAttribute("content", product.seo_image);
      window.document
        .getElementsByTagName("head")[0]
        .appendChild(metaImage);
    },
  },
};
</script>
