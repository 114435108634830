<template>
  <div class="wizardAP3 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-12">
          <!-- <div class="col-12 manejadoresWizard">
            <div>
              <div class="row d-flex align-items-center">
                <div class="col-lg-4 col-6 text-right">
                  <button
                    class="btn btn-outline-secondary primaryBtn d-flex align-items-center "
                    type="button"
                    id="button-addon2"
                    @click="steps('before')"
                    v-if="!disableBefore"
                  >
                    <i class="material-icons chevronAp3">chevron_left</i
                    >ANTERIOR
                  </button>
                </div>
                <div class="col-lg-4 col-3 d-none d-lg-block">
                  <img
                    src="../assets/img/since-wizard.png"
                    alt=""
                    width="80%"
                    class="d-inline"
                  />
                </div>
                <div class="col-lg-4 col-6 text-left">
                  <button
                    class="btn btn-outline-secondary primaryBtn d-flex align-items-center"
                    type="button"
                    id="button-addon2"
                    v-if="!disableNext"
                    @click="steps('next')"
                  >
                    PRÓXIMA<i class="material-icons chevronAp3"
                      >chevron_right</i
                    >
                  </button>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-12 pt-5 wizardTitle">
            <img
              src="../assets/img/line-big-cta-ap3.png"
              alt=""
              width="90%"
              class="d-inline mb-2"
            />
            <h3 class="h1Ap3 text-white d-none d-lg-block">
              ELIGE COMO DESEAS <strong class="fontGold">LA CARNE</strong>
            </h3>
            <h1 class="h1Ap3M text-white d-lg-none">
              ELIGE COMO DESEAS <strong class="fontGold">LA CARNE</strong>
            </h1>
            <img
              src="../assets/img/line-big-cta-ap3.png"
              alt=""
              width="90%"
              class="d-inline mb-5"
            />
          </div>
          <div class="col-12 pb-5 wizardOptions cantidad" v-if="step === 1">
            <div class="row">
              <div class="col-12">
                <h2 class="historiaTitle fontGold mr-3">1</h2>
                <p class="text-white subAp3 mb-5 pb-5 d-none d-lg-block">
                  Selecciona la cantidad que deseas
                </p>
                <p class="text-white subAp3M mb-4 pb-4 d-lg-none">
                  Selecciona la cantidad que deseas
                </p>
              </div>
              <div class="col-12">
                <div class="row text-left text-lg-center">
                  <div class="col">
                    
                    <div
                      class="custom-control custom-radio custom-control-inline radioAp3Cont"
                    >
                      <input
                        v-model="formData.unit"
                        type="radio"
                        id="unit1"
                        name="unit"
                        class="custom-control-input radioAp3"
                        value="1"
                      />
                      <span></span>
                      <label
                        class="pointer custom-control-label pl-3 font-weight-bolder"
                        for="unit1"
                        ><h5 class="">1 {{ data.unitType }}</h5></label
                      >
                    </div>
                  </div>
                  <div class="col mb-4">
                    <div
                      class="custom-control custom-radio custom-control-inline radioAp3Cont"
                    >
                      <input
                        v-model="formData.unit"
                        type="radio"
                        name="unit"
                        id="unit2"
                        class="custom-control-input radioAp3"
                        value="2"
                      />
                      <span></span>
                      <label
                        class="pointer custom-control-label pl-3 font-weight-bolder"
                        for="unit2"
                        ><h5 class="">2 {{ data.unitType }}</h5></label
                      >
                    </div>
                  </div>
                  <div class="col mb-4">
                    <div
                      class="custom-control custom-radio custom-control-inline radioAp3Cont"
                    >
                      <input
                        v-model="formData.unit"
                        type="radio"
                        name="unit"
                        id="unit3"
                        class="custom-control-input radioAp3"
                        value="3"
                      />
                      <span></span>
                      <label
                        class="pointer custom-control-label pl-3"
                        for="unit3"
                        ><h5 class="">3 {{ data.unitType }}</h5></label
                      >
                    </div>
                  </div>
                  <div class="col">
                    <div
                      class="custom-control custom-radio custom-control-inline radioAp3Cont"
                    >
                      <input
                        v-model="formData.unit"
                        type="radio"
                        name="unit"
                        id="unit4"
                        class="custom-control-input radioAp3"
                        value="4"
                      />
                      <span></span>
                      <label
                        class="pointer custom-control-label pl-3"
                        for="unit4"
                        ><h5 class="">4 {{ data.unitType }}</h5></label
                      >
                    </div>
                  </div>
                  <div class="col">
                    <div
                      class="custom-control custom-radio custom-control-inline radioAp3Cont"
                    >
                      <input
                        v-model="formData.unit"
                        type="radio"
                        id="unit5"
                        name="unit"
                        class="custom-control-input radioAp3"
                        value="5"
                      />
                      <span></span>
                      <label
                        class="pointer custom-control-label pl-3"
                        for="unit5"
                        ><h5 class="">5 {{ data.unitType }}</h5></label
                      >
                    </div>
                  </div>
                  <div class="col-12">
                    <div
                      class="row text-center justify-content-center d-flex mt-5"
                    >
                      <div class="col-lg-4 col-12">
                        <h5 class="fontGold">Otra cantidad</h5>
                        <b-form-input
                          v-model="formData.unit"
                          placeholder="Ingresa la cantidad"
                        ></b-form-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pb-5 wizardOptions porcion" v-if="step === 2">
            <div class="row">
              <div class="col-12">
                <h2 class="historiaTitle fontGold mr-3">2</h2>
                <p class="text-white subAp3 d-none d-lg-block mb-5 pb-5">
                  Selecciona una de las siguientes opciones
                </p>
                <p class="text-white subAp3M d-lg-none mb-5 pb-5">
                  Selecciona una de las siguientes opciones
                </p>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="text-center col-4">
                    <div
                      class="custom-control custom-radio custom-control-inline radioAp3Cont"
                    >
                      <input
                        v-model="formData.porcion"
                        type="radio"
                        @click="steps('entero')"
                        id="entero"
                        name="porcion"
                        class="custom-control-input radioAp3"
                        value="entero"
                      />
                      <span></span>
                      <label
                        class="pointer custom-control-label pl-3 font-weight-bolder"
                        for="entero"
                        ><h5 class="">Entero</h5></label
                      >
                    </div>
                  </div>
                  <div class="text-center col-4">
                    <div
                      class="custom-control custom-radio custom-control-inline radioAp3Cont"
                    >
                      <input
                        v-model="formData.porcion"
                        @click="steps('porcion')"
                        type="radio"
                        name="porcion"
                        id="porcionado"
                        value="porcionado"
                        class="custom-control-input radioAp3"
                      />
                      <span></span>
                      <label
                        class="pointer custom-control-label pl-3 font-weight-bolder"
                        for="porcionado"
                        ><h5 class="">Porcionado</h5></label
                      >
                    </div>
                  </div>
                  <div class="text-center col-4">
                    <div
                      class="custom-control custom-radio custom-control-inline radioAp3Cont"
                    >
                      <input
                        v-model="formData.porcion"
                        @click="steps('No aplica')"
                        type="radio"
                        name="porcion"
                        id="porcionNa"
                        value="No aplica"
                        class="custom-control-input radioAp3"
                      />
                      <span></span>
                      <label
                        class="pointer custom-control-label pl-3 font-weight-bolder"
                        for="porcionNa"
                        ><h5 class="">No aplica</h5></label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pb-5 wizardOptions tipoPorcion" v-if="showPorcion">
            <div class="row">
              <div class="col-12">
                <p class="text-white subAp3 d-lg-block d-none">
                  Tipos de porcionado
                </p>
                <p class="text-white subAp3M d-lg-none">Tipos de porcionado</p>
                <p
                  type="text"
                  @click="dialogVisible = true"
                  class="mb-5 pe-auto fontGold text-decoration-underline portionsCta d-none d-lg-block"
                >
                  Clic para ver guía de porcionado
                </p>
                <p
                  type="text"
                  @click="dialogVisibleM = true"
                  class="mb-5 pe-auto fontGold text-decoration-underline portionsCta d-lg-none"
                >
                  Clic para ver guía de porcionado
                </p>
                <el-dialog
                  :visible.sync="dialogVisible"
                  width="50%"
                  :before-close="handleClose"
                >
                  <div class="portionGuide px-4">
                    <p
                      class="h1Ap3M text-uppercase text-white mb-5 d-none d-lg-block pt-5"
                    >
                      Guía de <strong class="fontGold">porcionado</strong>
                    </p>
                    <img
                      src="../assets/img/portions.png"
                      alt=""
                      width="100%"
                      class="mb-5"
                    />
                    <p class="pb-4">
                      * No todos los porcionados aplican para todos los tipos de
                      corte.
                    </p>
                  </div>
                </el-dialog>
                <el-dialog
                  :visible.sync="dialogVisibleM"
                  width="90%"
                  :before-close="handleClose"
                >
                  <div class="portionGuide">
                    <p
                      class="h1Ap3M text-uppercase text-white mb-5 d-lg-none pt-5"
                    >
                      Guía de<br /><strong class="fontGold">porcionado</strong>
                    </p>
                    <img
                      src="../assets/img/portionsM.png"
                      alt=""
                      width="100%"
                      class="mb-5"
                    />
                    <p class="pb-4">
                      * No todos los porcionados aplican para todos los tipos de
                      corte.
                    </p>
                  </div>
                </el-dialog>
              </div>
              <div class="col-12">
                <div class="row text-left text-lg-center">
                  <div
                    class="col-6 mb-4 col-lg"
                    v-for="portioned in data.portioned"
                  >
                    <div
                      class="custom-control custom-radio custom-control-inline radioAp3Cont"
                    >
                      <input
                        v-model="formData.tipoPorcion"
                        type="radio"
                        :id="portioned.name"
                        :value="portioned.name"
                        name="tipoPorcion"
                        class="custom-control-input radioAp3"
                      />
                      <span></span>
                      <label
                        class="pointer custom-control-label pl-3 font-weight-bolder"
                        :for="portioned.name"
                        ><h5 class="">{{ portioned.name }}</h5></label
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pb-5 wizardOptions empacado" v-if="step === 3">
            <div class="row">
              <div class="col-12">
                <h2 class="historiaTitle fontGold mr-3">3</h2>
                <p class="text-white subAp3 d-none d-lg-block mb-5 pb-5">
                  Selecciona el tipo de empaque
                </p>
                <p class="text-white subAp3M d-lg-none mb-4 pb-4">
                  Selecciona el tipo de empaque
                </p>
              </div>
              <div class="col-12">
                <div class="row text-left text-lg-center">
                  <div
                    class="col-12 col-lg mb-4"
                    v-for="empaque in data.packed"
                  >
                    <div
                      class="custom-control custom-radio custom-control-inline radioAp3Cont"
                    >
                      <input
                        v-model="formData.tipoEmpaque"
                        type="radio"
                        :id="empaque._id"
                        :name="empaque._id"
                        :value="[{ name: empaque.name, cost: '0' }]"
                        class="custom-control-input radioAp3"
                      />
                      <span></span>
                      <label
                        v-if="empaque.name !== 'Al vacío'"
                        class="pointer custom-control-label pl-3 font-weight-bolder"
                        :for="empaque._id"
                        ><h5 class="">
                          {{ empaque.name }} -<small class="fontGold"> $0</small>
                        </h5></label
                      >
                      <label
                        v-if="empaque.name == 'Al vacío'"
                        class="pointer custom-control-label pl-3 font-weight-bolder"
                        :for="empaque._id"
                        ><h5 class="">
                          {{ empaque.name }} -<small class="fontGold"> $2000</small>
                        </h5></label
                      >
                    </div>
                  </div>
                  
                  <div
                    class="col-12 col-lg mb-12"
                    v-if="data.packed ? data.packed.length === 0: true "
                  >
                    <div
                      class="custom-control custom-radio custom-control-inline radioAp3Cont"
                    >
                      <input
                        v-model="formData.tipoEmpaque"
                        type="radio"
                        id="tipoEmpaqueNa"
                        name="tipoEmpaqueNa"
                        :value="[{ name: 'No aplica', cost: '0' }]"
                        class="custom-control-input radioAp3"
                      />
                      <span></span>
                      <label
                        class="pointer custom-control-label pl-3 font-weight-bolder"
                        for="tipoEmpaqueNa" >
                          <h5 class=""> No aplica </h5>
                        </label>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div
            class="col-12 pb-5 wizardOptions especificacion"
            v-if="step === 4"
          >
            <div class="row">
              <div class="col-12">
                <h2 class="historiaTitle fontGold mr-3">4</h2>
                <p class="text-white subAp3 d-none d-lg-block">
                  Especificaciones adicionales
                </p>
                <p class="text-white subAp3M d-lg-none mb-3 pb-3">
                  Especificaciones adicionales
                </p>
                <p class="text-white mb-5 pb-5">
                <strong >Aplica para las categorías Carne de Res y Carne de Cerdo </strong>
                <br/>
                <br/>
                  Por favor <strong class='text-sub' >menciona la cantidad de grasa</strong> que deseas en la pieza si es el caso, 
                  (sin grasa, poca grasa o con toda la grasa), si crees que debamos tener en cuenta 
                  algo más, detállalo también en el siguiente cuadro:
                </p>
              </div>
              <div class="col-12">
                <div>
                  <b-form-textarea
                    v-model="formData.specifications"
                    id="textarea"
                    placeholder="Escribe aquí tus especificaciones adicionales"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pb-5 wizardOptions resumen" v-if="step === 5">
            <div class="row">
              <div class="col-12">
                <h2 class="historiaTitle fontGold mr-3">5</h2>
                <p class="text-white subAp3 d-none d-lg-block mb-5 pb-5">
                  Resumen de tu pedido
                </p>
                <p class="text-white subAp3M d-lg-none mb-3 pb-3">
                  Resumen de tu pedido
                </p>
              </div>
              <div class="col-lg-6 col-12 mb-4">
                <div class="imgContainer">
                  <img
                    v-if="data.main_image"
                    :src="data.main_image && typeof data.main_image === 'object' ? data.main_image.url: data.main_image "
                    alt=""
                    width="100%"
                    class="d-inline productImg borderGold"
                  />
                  <img
                    v-else
                    src="https://storage.googleapis.com/digammastudio/ap3/images/AP32.jpg"
                    alt=""
                    width="100%"
                    class="d-inline productImg borderGold"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="text-left fontLight">
                  <p
                    class="productCategory mb-0"
                    v-for="category in data.categories"
                  >
                    {{ category }}
                  </p>
                  <h4 class="fontGold mb-3 font-weight-bolder">
                    {{ data.name }}
                  </h4>
                  <p class="mb-0">
                    Cantidad:
                    <strong>{{ formData.unit }} {{ data.unitType }}</strong>
                  </p>
                  <p class="mb-0">
                    Porcionado: <strong>{{ formData.tipoPorcion }}</strong>
                  </p>
                  <p class="mb-0">
                    Empaque: <strong>{{ formData.tipoEmpaque[0].name }}</strong>
                  </p>
                  <p class="" v-if="formData.specifications">
                    Especificaciones:
                    <strong>{{ formData.specifications }}</strong>
                  </p>
                  <p class="" v-if="!formData.specifications"></p>
                  <p class="productCategory mb-0 fontLight">Valor aproximado</p>
                  <p
                    class="productPrice"
                    v-if="formData.tipoEmpaque[0].name == 'Al vacío'"
                  >
                    {{
                      (formData.total =
                        (data.price * formData.unit + 2000) | currency)
                    }}
                  </p>
                  <p class="productPrice" v-else>
                    {{
                      (formData.total = (data.price * formData.unit) | currency)
                    }}
                  </p>
                  <small
                    >*El valor de tu orden puede variar según la cantidad final
                    del producto luego de pesarse en básculo o según la cantidad
                    de bolsas al vacío necesarias para empacar el
                    producto</small
                  >
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-12">
                <a @click="addToCart()">
                  <button
                    class="btn btn-outline-secondary primaryBtn fullWithAp3 backRed"
                    type="button"
                    id="button-addon2"
                  >
                    AGREGAR AL CARRITO
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div class="col-12 manejadoresWizard mb-5 p-lg-4 p-2 rounded-lg">
            <div>
              <div class="row d-flex align-items-center">
                <div class="col-lg-6 col-6 text-right">
                  <button
                    class="btn btn-outline-secondary primaryBtn d-flex align-items-center"
                    type="button"
                    id="button-addon2"
                    @click="steps('before')"
                    v-if="!disableBefore"
                  >
                    <i class="material-icons chevronAp3">chevron_left</i
                    >ANTERIOR
                  </button>
                </div>
                <!-- <div class="col-lg-4 col-3 d-none d-lg-block">
                  <img
                    src="../assets/img/since-wizard.png"
                    alt=""
                    width="50%"
                    class="d-inline"
                  />
                </div> -->
                <div class="col-lg-6 col-6 text-left">
                  <button
                    class="backRed btn btn-outline-secondary primaryBtn d-flex align-items-center float-right"
                    type="button"
                    id="button-addon3"
                    v-if="checkValidate"
                    @click="steps('next')"
                  >
                    SIGUIENTE<i class="material-icons chevronAp3"
                      >chevron_right</i
                    >
                  </button>
                  <button
                    class="backRed btn btn-outline-secondary primaryBtn d-flex align-items-center float-right disabled"
                    type="button"
                    id="button-addon3"
                    v-else
                  >
                    SIGUIENTE
                    <i class="material-icons chevronAp3">chevron_right</i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 pb-5 wizardPath d-none d-lg-block px-0">
            <div class="d-flex justify-content-between">
              <div>
                <p
                  v-if="step == 1"
                  class="stepsAp3 stepActive fontLight font-weight-bolder pt-2 mx-0"
                >
                  1. CANTIDAD
                </p>
                <p v-else class="stepsAp3 fontLight pt-2 mx-0">1. CANTIDAD</p>
              </div>
              <div>
                <p
                  v-if="step == 2"
                  class="stepsAp3 stepActive fontLight font-weight-bolder pt-2 mx-0"
                >
                  2. PORCIONADO
                </p>
                <p v-else class="stepsAp3 fontLight pt-2 mx-0">2. PORCIONADO</p>
              </div>
              <div>
                <p
                  v-if="step == 3"
                  class="stepsAp3 stepActive fontLight font-weight-bolder pt-2 mx-0"
                >
                  3. EMPACADO
                </p>
                <p v-else class="stepsAp3 fontLight pt-2 mx-0">3. EMPACADO</p>
              </div>
              <div>
                <p
                  v-if="step == 4"
                  class="stepsAp3 stepActive fontLight font-weight-bolder pt-2 mx-0"
                >
                  4. ADICIONAL
                </p>
                <p v-else class="stepsAp3 fontLight pt-2 mx-0">4. ADICIONAL</p>
              </div>
              <div>
                <p
                  v-if="step == 5"
                  class="stepsAp3 stepActive fontLight font-weight-bolder pt-2 mx-0"
                >
                  5. RESULTADO
                </p>
                <p v-else class="stepsAp3 fontLight pt-2 mx-0">5. RESULTADO</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-12">
          <div class="productInfo p-4 rounded-lg">
            <img
              v-if="data.main_image"
              :src="data.main_image && typeof data.main_image === 'object' ? data.main_image.url: data.main_image "
              alt=""
              width="50%"
              class="d-inline rounded-circle borderGold mb-3"
            />
            <img
              v-else
              src="https://storage.googleapis.com/digammastudio/ap3/images/AP32.jpg"
              alt=""
              width="50%"
              class="d-inline rounded-circle borderGold mb-3"
            />
            <h5 class="text-white font-weight-bolder text-capitalize">
              {{ data.name }}
            </h5>
            <p class="productPrice mb-4">{{ data.price | currency }}</p>
            <img
              src="../assets/img/line-cta-ap3.png"
              alt=""
              width="100%"
              class="d-inline mb-4"
            />
            <p class="fontLight text-left description">
              {{ data.description }}
            </p>
            <div class="row d-flex align-items-center mt-5" v-if="(data.weight!='' && data.weight!= undefined && data.weight!= null)">
              <div class="col fontLight text-left">
                <p class="fontLight description font-weight-bold">
                  INFORMACIÓN DEL PRODUCTO PESO APROXIMADO DE LA PIEZA ENTERA
                </p>
              </div>
              <div class="col">
                <h4 class="fontGold">{{ data.weight }} {{ data.weightType }}</h4>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col">
                <!-- <img
                  src="../assets/img/RES.png"
                  alt=""
                  width="100%"
                  class="d-inline mb-5"
                /> -->
              </div>
            </div>
            <div class="row" v-if="data.metodos_de_coccions.length > 0">
              <div class="col-12">
                <p
                  class="fontLight description font-weight-bold text-uppercase"
                >
                  Métodos de cocción {{ data.name }}
                </p>
                <div
                  class="fontLight description metodosC row justify-content-center"
                >
                  <div v-for="cooking in data.metodos_de_coccions" class="col-5 px-0 mb-2">
                    {{ cooking.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const {
  mapActions: stepMapActions,
  mapGetters: stepMapGetters,
} = createNamespacedHelpers("wizard");
const {
  mapActions: cartMapActions,
  mapGetters: cartMapGetters,
} = createNamespacedHelpers("cart");
export default {
  name: "WizardComp",
  props: ["data"],
  data() {
    return {
      dialogVisible: false,
      dialogVisibleM: false,
      costoEmpaque: 0,
      disabled: true,
      formData: {
        unit: "",
        // unitType: "",
        porcion: "",
        tipoPorcion: "",
        tipoEmpaque: "",
        specifications: "",
        total: 0,
      },
    };
  },
  computed: {
    ...stepMapGetters(["step", "disableBefore", "disableNext", "showPorcion"]),
    ...cartMapGetters(["cart", "subtotal", "total"]),
    checkValidate: function handleCheck() {

      this.hideButton();

      if (this.step === 1 && this.formData.unit !== "") {
        return true;
      }

      if (this.step === 3 && this.formData.tipoEmpaque !== "") {
        return true;
      }

      if (this.step === 4 && this.formData.specifications !== "") {
        return true;
      }

      if (this.step === 5) {
        return true;
      }

      if (
        this.step === 2 &&
        this.formData.tipoPorcion !== "" &&
        this.formData.porcion !== ""
      ) {
        return true;
      }

      if (this.step === 2 && this.formData.porcion !== "porcionado") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (this.formData.tipoEmpaque[0].name == "Al vacío")
      this.costoEmpaque = 2000;
  },
  methods: {
    ...stepMapActions(["SET_STEP"]),
    ...cartMapActions(["SET_CART", "SET_TOTAL", "SET_SUBTOTAL", "CLEAR_CART"]),
    steps(action) {
      this.SET_STEP(action);
      this.disabled = false;
    },
    addToCart() {
      if (this.formData.tipoEmpaque[0].name == "Al vacío")
        this.formData.tipoEmpaque[0].cost = 2000;
      
      const dataProduct = {
        ...this.data,
        ...this.formData
      };

      this.SET_CART(dataProduct);
      this.$router.push("/carrito-de-compras");
    },
    handleClose() {
      this.dialogVisible = false;
      this.dialogVisibleM = false;
    },
    hideButton() {
      const button = document.getElementById("button-addon3");
      if (this.step == 5 && button) {
        button.className += " hide";
      }else if(button){
        button.className = button.className.split(" ")
          .filter(item=>item!= "hide")
          .join(' ');;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.text-sub{
    border-bottom: 1px solid #cebf7c;
    font-size: 1.1rem;
}
</style>
