<template>
  <div class="contactanosAP3">
      <div class="container pt-5">
          <div class="row">
              <div class="col-2"></div>
              <div class="col-8">
                <div>
                    <img src="../assets/img/pesa-ap3.png" alt="" width="150" class="d-inline mb-5">
                </div>
                <img src="../assets/img/line-big-cta-ap3.png" alt="" width="100%" class="d-inline mb-2">
                <h1 class="h1Ap3 text-white">
                    ESTAMOS EN <strong class="fontGold">CONSTRUCCIÓN</strong>
                </h1>
                <img src="../assets/img/line-big-cta-ap3.png" alt="" width="100%" class="d-inline mb-5">
              </div>
              <div class="col-2"></div>
          </div>
      </div>
    <!-- <Menu></Menu>
    <Hero></Hero>
    <TradicionBadge></TradicionBadge>
    <PersonalizaCta></PersonalizaCta>
    <TiendaCta></TiendaCta>
    <div class="destacados container mt-5">
      <h4 class="h1Ap3 text-white text-uppercase mb-4 text-left">Destacados</h4>
      <div class="d-flex">
        <Destacados
          :product="product"
          v-for="product in destacados"
        ></Destacados>
      </div>
    </div> -->
    <Contactanos></Contactanos>
    <!-- <Footer></Footer>
    <Subscribe></Subscribe>
    <Copyright></Copyright> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";
import Subscribe from "../components/Subscribe.vue";
import Copyright from "../components/Copyright.vue";
import Contactanos from "../components/Contactanos.vue";
import TiendaCta from "../components/TiendaCta.vue";
import PersonalizaCta from "../components/PersonalizaCta.vue";
import TradicionBadge from "../components/TradicionBadge.vue";
import Hero from "../components/Hero.vue";
import Destacados from "../components/Destacados.vue";
import ProductService from "../services/Products";

export default {
  name: "Under",
  components: {
    Menu,
    Footer,
    Subscribe,
    Copyright,
    Contactanos,
    TiendaCta,
    PersonalizaCta,
    TradicionBadge,
    Hero,
    Destacados,
  },
  data() {
    return {
      destacados: "",
    };
  },
  mounted() {
    this.getDestacados();
    this.addMeta();
  },
  methods: {
    async getDestacados() {
      const destacados = await ProductService.getProductsByQuery({
        query: {
          featured: true
        },
        limit: 4
      });
      this.destacados = destacados;
    },
    addMeta() {
      window.document.title = "Home | AP3";
      const metaDescription = document.createElement("meta");
      metaDescription.setAttribute("name", "description");
      metaDescription.setAttribute("content", "Home");
      window.document
        .getElementsByTagName("head")[0]
        .appendChild(metaDescription);
    },
  },
};
</script>
