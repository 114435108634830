'use strict'
import axios from 'axios'
import configService from "../../configs/config"
const products = []

export default {
  namespaced: true,
  state: {
    subcategory: "",
    products: ""
  },
  actions: {
    async SET_SUBCATEGORY({ commit }, subcategory) {
      const products = await axios.post(`${configService.apiUrl}/products/byCategory`, {categories: [subcategory]})
      commit('SET_SUBCATEGORY', subcategory)
      commit('SET_PRODUCTS', products.data.data)
    },
    async SET_PRODUCTS({ commit }, products) {
      commit('SET_PRODUCTS', products)
    }
  },
  mutations: {
    SET_SUBCATEGORY(state, subcategory) {
      state.subcategory = subcategory
    },
    SET_PRODUCTS(state, products) {
      state.products = products
    }
  },
  getters: {
    subcategory: state => state.subcategory,
    products: state => state.products
  }
}