<template>
    <div class="heroAP3 align-items-center d-flex pb-5">

        <!-- desktop version -->
        <div class="container d-none d-lg-block">
            <div class="row py-2 d-flex align-items-center">
                <div class="col-4">
                    <img src="../assets/img/expertos-ap3.png" alt="" width="100%" class="d-inline mb-5">
                    <div class="row">
                        <div class="col-3">
                            <img src="../assets/img/number-expertos-1.png" alt="" width="80%" class="d-inline mb-5">
                        </div>
                        <div class="col-9">
                            <p class="text-left text-white"><span class="fontGold">Nuestra carne es<br>de alta calidad,<br></span>100% colombiana</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <img src="../assets/img/number-expertos-2.png" alt="" width="80%" class="d-inline mb-5">
                        </div>
                        <div class="col-9">
                            <p class="text-left text-white"><span class="fontGold">Logramos verificar cada uno<br>de los procesos de la cadena,<br></span>garantizando la eficiencia,<br>higiene y calidad.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <img src="../assets/img/number-expertos-3.png" alt="" width="80%" class="d-inline mb-5">
                        </div>
                        <div class="col-9">
                            <p class="text-left text-white"><span class="fontGold">En los puntos de venta AP3,<br>nuestros clientes encontrarán<br></span>servicio, asesoría y atención<br>personalizada.</p>
                        </div>
                    </div>
                    <a href="/comprar-ya">
                        <button class="btn btn-outline-secondary primaryBtn fullWithAp3" type="button" id="button-addon2">HAZ TU PEDIDO YA</button>
                    </a>
                </div>
                <div class="col-8 pt-5">
                    <img src="../assets/img/carne-header-ap3.png" alt="" width="100%" class="d-inline mb-5">
                </div>
            </div>
        </div>
        <!-- end desktop version -->

        <!-- mobile version -->
        <div class="container d-lg-none">
            <div class="row py-2 d-flex align-items-center">
                <div class="col-12">
                    <img src="../assets/img/carne-header-mobile-ap3.png" alt="" width="100%" class="d-inline mb-5">
                    <div class="row">
                        <div class="col-3">
                            <img src="../assets/img/number-expertos-1.png" alt="" width="80%" class="d-inline mb-5">
                        </div>
                        <div class="col-9">
                            <p class="text-left text-white"><span class="fontGold">Nuestra carne es<br>de alta calidad,<br></span>100% colombiana</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <img src="../assets/img/number-expertos-2.png" alt="" width="80%" class="d-inline mb-5">
                        </div>
                        <div class="col-9">
                            <p class="text-left text-white"><span class="fontGold">Logramos verificar cada uno<br>de los procesos de la cadena,<br></span>garantizando la eficiencia,<br>higiene y calidad.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <img src="../assets/img/number-expertos-3.png" alt="" width="80%" class="d-inline mb-5">
                        </div>
                        <div class="col-9">
                            <p class="text-left text-white"><span class="fontGold">En los puntos de venta AP3,<br>nuestros clientes encontrarán<br></span>servicio, asesoría y atención<br>personalizada.</p>
                        </div>
                    </div>
                    <a href="/comprar-ya">
                        <button class="btn btn-outline-secondary primaryBtn fullWithAp3" type="button" id="button-addon2">HAZ TU PEDIDO YA</button>
                    </a>
                </div>
            </div>
        </div>
        <!-- end mobile version -->

    </div>
</template>

<script>
export default {
  name: 'Hero',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
