<template>
    <div class="contactanosAP3 py-5">
        <div class="container py-4">
            <div class="row">
                <div class="col-1"></div>
                <div class="col-lg-5 col-12 pb-4">
                    <img src="../assets/img/machine-ap3.png" alt="" width="120" class="d-inline mb-5">
                    <h3 class="h3Ap3 text-white">CONTÁCTANOS</h3>
                    <p class="captionAp3 text-white">¿Tienes alguna duda sobre los productos o quieres <br>contarnos algo?, <span class="fontGold">aquí te dejamos este espacio.</span></p>
                </div>
                <div class="col-lg-5 col-12">
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control mb-3 inputGold" placeholder="Nombre">
                            <input type="email" class="form-control mb-3 inputGold" placeholder="E-Mail" id="exampleInputEmail1" aria-describedby="emailHelp">
                            <textarea class="form-control mb-3 inputGold" placeholder="Mensaje" id="exampleFormControlTextarea1" rows="3"></textarea>
                            <button class="btn btn-outline-secondary primaryBtn" type="button" id="button-addon2">ENVIAR</button>
                        </div>
                    </form>
                </div>
                <div class="col-1"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Contactanos',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
