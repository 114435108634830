'use strict'
const cart = JSON.parse(localStorage.getItem("cart")) || []

export default {
  namespaced: true,
  state: {
    cart: JSON.parse(localStorage.getItem("cart")) || [],
    subtotal: 0,
    total: 0,
    isActive: false
  },
  actions: {
    async SET_CART({ commit }, product) {
      if(cart.length > 0) {
        let item = cart.find(item => item._id === product._id && parseInt(item.size) === parseInt(product.size) && parseInt(item.color) === parseInt(product.color))
        if(item) {
          item.qty = product.qty
        }else{
          cart.push(product)
        }
      }
      if(cart.length === 0){
        cart.push(product)
      }
      commit('SET_CART', cart)
    },
    async CLEAR_CART({ commit }, product) {
      commit('CLEAR_CART', product)
    },
    SET_SUBTOTAL({ commit }) {
      let sub = 0;
      cart.map(product => {
        sub += parseInt(product.price * product.qty);
      });
      commit('SET_SUBTOTAL', sub)
    },
    SET_TOTAL({ commit }, shipping) {
      let total = 0;
      cart.map(product => {
        total += parseInt(product.total);
      });
      commit('SET_TOTAL', total)
    },
    SET_ISACTIVE({ commit }, active) {
      commit('SET_ISACTIVE', active)
    }
  },
  mutations: {
    SET_CART(state, cart) {
      state.cart = cart
      localStorage.setItem("cart", JSON.stringify(state.cart))
    },
    SET_SUBTOTAL(state, sub) {
      state.subtotal = sub
    },
    SET_TOTAL(state, total) {
      state.total = total
    },
    SET_ISACTIVE(state, active) {
      state.isActive = active
    },
    CLEAR_CART(state, payload){
      let item = state.cart.find(item => item._id === payload._id) // quité  === parseInt(payload.size
      let index = state.cart.indexOf(item)
      state.cart.splice(index, 1)
      state.cartCount--
      state.total = state.total - item.price;
      state.subtotal = state.subtotal - item.price;
      localStorage.setItem("cart", JSON.stringify(state.cart))
    }
  },
  getters: {
    cart: state => state.cart,
    subtotal: state => state.subtotal,
    total: state => state.total,
    isActive: state => state.isActive
  },
}