<template>
  <div class="home">
    <Menu></Menu>
    <Hero></Hero>
    <TradicionBadge></TradicionBadge>
    <PersonalizaCta></PersonalizaCta>
    <TiendaCta></TiendaCta>
    <div class="destacados container mt-5">
      <h4 class="h1Ap3 text-white text-uppercase mb-4 text-left">Destacados</h4>
      <div class="d-flex">
        <Destacados
          :product="product"
          v-for="product in destacados"
        ></Destacados>
      </div>
    </div>
    <Footer></Footer>
    <InfoStores></InfoStores>
    <Copyright></Copyright>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";
import Subscribe from "../components/Subscribe.vue";
import InfoStores from "../components/InfoStores.vue";
import Copyright from "../components/Copyright.vue";
import Contactanos from "../components/Contactanos.vue";
import TiendaCta from "../components/TiendaCta.vue";
import PersonalizaCta from "../components/PersonalizaCta.vue";
import TradicionBadge from "../components/TradicionBadge.vue";
import Hero from "../components/Hero.vue";
import Destacados from "../components/Destacados.vue";
import ProductService from "../services/Products";
import qs from 'qs'

export default {
  name: "Home",
  components: {
    Menu,
    Footer,
    Subscribe,
    Copyright,
    Contactanos,
    TiendaCta,
    PersonalizaCta,
    TradicionBadge,
    Hero,
    Destacados,
    InfoStores,
  },
  data() {
    return {
      destacados: "",
    };
  },
  mounted() {
    this.getDestacados();
    this.addMeta();
  },
  methods: {
    async getDestacados() {
      const query = qs.stringify({
        _where: {featured: true},
        _limit:4
      });
      
      const destacados = await ProductService.getProductsByQuery(query);
      this.destacados = destacados;
    },
    addMeta() {
      window.document.title = "Home | AP3";
      const metaDescription = document.createElement("meta");
      metaDescription.setAttribute("name", "description");
      metaDescription.setAttribute("content", "Home");
      window.document
        .getElementsByTagName("head")[0]
        .appendChild(metaDescription);
    },
  },
};
</script>
