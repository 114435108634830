import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
// import Contacto from '../views/Contacto.vue'
import Wizard from '../views/Wizard.vue'
import Productos from '../views/Productos.vue'
import Categoria from '../views/Categoria.vue'
import RealizarPedido from '../views/RealizarPedido.vue'
import Cart from '../views/Cart.vue'
import Landing from '../views/Landing.vue'
import Politicas from '../views/Politicas.vue'
import Under from '../views/Under.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:id/compra-ya',
    name: 'Wizard',
    component: Wizard
  },
  {
    path: '/productos',
    name: 'Productos',
    component: Productos
  },
  {
    path: '/categoria/:category',
    name: 'Categoría',
    component: Categoria
  },
  {
    path: '/comprar-ya',
    name: 'Compra ya',
    component: Productos
  },
  // {
  //   path: '/contacto',
  //   name: 'Contacto',
  //   component: Contacto
  // },
  {
    path: '/realizar-pedido',
    name: 'RealizarPedido',
    component: RealizarPedido
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  // {
  //   path: '/contacto',
  //   name: 'Contacto',
  //   component: Contacto
  // },
  {
    path: '/carrito-de-compras',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/redireccion',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/politicas',
    name: 'Politicas',
    component: Politicas
  },
  {
    path: '/under',
    name: 'Under',
    component: Under
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
