<template>
    <div class="subscribeAP3 pb-3">
        <div class="container">
            <div class="row d-flex align-items-center py-3 border-top border-white pt-4">
                <div class="col-lg-5 col-12"></div>
                <div class="col-lg-7 col-12 text-left">
                    <div class="row">
                        <div class="col-lg-4 col-12">
                            <p class="font-weight-bolder fontGold text-lg-left text-center">SUSCRÍBETE A  NUESTRO NEWSLETTER</p>
                        </div>
                        <div class="col-lg-8 col-12">
                            <div class="input-group mb-3">
                                <input type="text" class="form-control roundedForm" placeholder="Escribe tu e-mail" aria-label="Recipient's username" aria-describedby="button-addon2">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary roundedBtn secondaryBtn" type="button" id="button-addon2">ENVIAR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Subscribe',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
