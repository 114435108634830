<template>
  <div class="contacto">
    <Menu></Menu>
    <!-- <div class="contactoCont pb-5">
      <div class="container py-5">
        <div class="row py-5">
          <div class="col-lg-5">
            <h1 class="h1Ap3 text-white text-left">
              ¿ALGUNA DUDA? <br /><strong class="fontGold">CONTÁCTANOS-</strong>
            </h1>
            <p class="text-white fontLibre font-italic text-left">
              Estamos para ti, si tienes alguna solicitud, inquietud o problema.
              No dudes en enviarnos un mensaje y te responderémos en el tiempo
              más breve posible.
            </p>
            <form class="mt-5">
              <div class="form-group text-left">
                <input
                  v-model="name"
                  :class="{ 'is-invalid': submitted && $v.name.$error }"
                  type="text"
                  class="form-control mb-3 inputGold"
                  placeholder="Nombre"
                />
                <div
                  v-if="submitted && !$v.name.required"
                  class="invalid-feedback"
                >
                  Nombre requerido
                </div>
                <input
                  v-model="email"
                  type="email"
                  class="form-control mb-3 inputGold"
                  placeholder="E-Mail"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
                <div
                  v-if="submitted && !$v.email.required"
                  class="invalid-feedback"
                >
                  Email requerido
                </div>
                <textarea
                  v-model="message"
                  class="form-control mb-3 inputGold"
                  placeholder="Mensaje"
                  id="exampleFormControlTextarea1"
                  rows="3"
                ></textarea>
                <div
                  v-if="submitted && !$v.message.required"
                  class="invalid-feedback"
                >
                  Mensaje requerido
                </div>
                <button
                  @click="form"
                  class="btn btn-outline-secondary primaryBtn"
                  type="button"
                  id="button-addon2"
                >
                  ENVIAR
                </button>
              </div>
            </form>
          </div>
          <div class="col-lg-7"></div>
        </div>
      </div>
    </div> -->
    <div class="container">
      <p class="p-5 text-white text-justify">
        <br /><br />
        <br /><br />
        <br /><br />
        <span>
          AP3 CARNES S.A.
          <br />
          NIT 900.262.893-9
          <br />
          CRA 40 36 SUR 30
          <br />
          TEL.: (4) 331 29 91
          <br />
          ENVIGADO (ANT)
        </span>
        <br /><br />
        <strong>
          POLÍTICA DE TRATAMIENTO DE DATOS PERSONALES AP3 CARNES S.A.
        </strong>
        <br /><br />
        Estimado cliente,
        <br /><br />
        La empresa AP3 CARNES S.A. identificada con NIT 900.262.893-9 domicilio
        principal en la Carrera 40 36 Sur 30 Teléfono 331 29 91 del Municipio de
        Envigado (Antioquia), dando cumplimiento a los dispuesto en la Ley 1581
        de 2012, “Por el cual se dictan disposiciones generales para la
        protección de datos personales” y de conformidad con lo señalado en el
        Decreto 1377 de 2013, a través de este aviso, informa a los titulares de
        datos personales que se encuentren en nuestras bases de datos que las
        políticas de tratamiento de datos personales son:
        <br /><br />
        <strong>
          FINALIDAD CON LA QUE SE EFECTÚA LA RECOLECCIÓN DE DATOS PERSONALES Y
          TRATAMIENTO DE LOS MISMOS:
        </strong>
        <br /><br />
        a) Ejecutar el cumplimiento de contactos con sus clientes, proveedores y
        empleados. b) Hacer contacto en caso de quejas, reclamos, sugerencias o
        evaluar la calidad del servicio. c) Enviar información comercial,
        publicidad o promoción sobre los productos con el fin de impulsar los
        productos de AP3 CARNES S.A. d) Desarrollar el proceso de selección,
        evaluación, vinculación laboral. e) Realizar pedidos y pagos a los
        proveedores y reportar información tributaria referente a las compras,
        frente a sus proveedores.
        <br /><br />
        <strong> TRATAMIENTO AL CUAL SERÁN SOMETIDOS LOS DATOS: </strong>
        <br /><br />
        Los datos de clientes y proveedores no podrán ser obtenidos o divulgados
        sin previa autorización o mandato legal o judicial que revele el
        consentimiento del titular. Los datos de clientes y proveedores deben
        ser completos, veraces, actualizados, comprobables y comprensibles. Los
        datos de clientes y proveedores se deben manejar con las respectivas
        medidas técnicas, humanas y administrativas necesarias para brindar la
        seguridad correspondiente evitando modificación indebida, eliminación y
        acceso no autorizado. El tratamiento de los datos de clientes y
        proveedores estarán bajo la responsabilidad de las personas delegadas
        para tal fin, quienes están obligadas a garantizar la reserva de la
        información, inclusive después de finalizada su relación con AP3 CARNES
        S.A.
        <br /><br />
        <strong> DERECHOS DE LOS TITULARES DE LOS DATOS PERSONALES: </strong>
        <br /><br />

        a) Conocer nuestra política de tratamiento y las modificaciones de ésta
        de manera previa a la implementación. b) El titular de sus datos tiene
        derecho a actualizarlos, revocar y conocer la autorización para su
        tratamiento, solicitar la supresión cuando proceda y acceder en forma
        gratuita a los mismos. El responsable del tratamiento de sus datos es
        AP3 CARNES S.A., para ejercer los derechos sobre éstos puede escribir al
        correo electrónico contabilidad1@ap3carnes.com y para conocer la
        política de privacidad puede ingresar a nuestra página web
        www.ap3carnes.com c) A ser informado sobre la (s) finalidad (es) de la
        recolección de los datos personales, las cuales están contenidas en la
        política enviada por correo electrónico. d) A que AP3 CARNES S.A. le
        garantice la confidencialidad, libertad, seguridad, veracidad,
        transparencia, acceso y circulación restringida de sus datos.
        <br /><br />
        <strong>
          ÁREA RESPONSABLE DE LA ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS
          RELACIONADOS CON DATOS PERSONALES:
        </strong>
        <br /><br />

        AP3 CARNES S.A. ha designado al área administrativa – contable como
        responsable de la recepción y atención de peticiones, quejas, reclamos y
        consultas de todo tipo, relacionadas con datos personales, a excepción
        de las solicitudes correspondientes a recurso humano, que manejará la
        Dependencia de Recursos Humanos; área encargada que de manera específica
        tramitará las consultas y reclamaciones en materia de datos personales
        de conformidad con la Ley y esta política.
        <br /><br />

        PROCEDIMIENTO PARA QUE LOS TITULARES DE LA INFORMACIÓN PUEDAN EJERCER
        LOS DERECHOS A CONOCER, ACTUALIZAR, RECTIFICAR Y SUPRIMIR INFORMACIÓN Y
        REVOCAR LA AUTORIZACION:
        <br /><br />

        AP3 CARNES S.A. dispone de los mecanismos para que el titular y/o sus
        representantes formulen consultas respecto de cuáles son los datos
        personales del titular que reposan en las bases de datos de la compañía.
        La consulta se formulará por el medio habilitado por el responsable del
        tratamiento, tales y como por ejemplo, llamadas telefónicas y/o
        solicitud expresa al correo electrónico definido para tal fin. Los datos
        de contacto son: Dirección: Carrera 40 36 Sur 30 Envigado (Antioquia)
        Teléfono: (4) 331 29 91 Correo electrónico: mercadeo2@ap3carnes.com

        <br /><br />
        <strong> GENE RALIDADES: </strong>
        <br /><br />

        Si un dato personal es proporcionado, dicha información será utilizada
        sólo para los propósitos aquí señalados. Cuando se proporcione
        información personal a terceros, AP3 CARNES S.A. advierte sobre la
        necesidad de proteger la información personal con medidas de seguridad
        apropiadas, prohíbe el uso de la información para fines propios y se
        solicita que no se divulgue la información personal a otros. AP3 CARNES
        S.A. no es responsable de la inexactitud de los datos suministrada por
        el titular. La divulgación del tratamiento de la base de datos se
        realizará a través de correo electrónico. No podrá utilizarse la base de
        datos del tercero titular de los mismos que expresamente NO autorice a
        su utilización a AP3 CARNES S.A. AP3 CARNES S.A. se reservará el derecho
        de modificar su Política de Tratamiento de Datos Personales en cualquier
        momento y cualquier cambio será informado oportunamente por correo
        electrónico.
        <br /><br />

        <strong> VIGENCIA: </strong>
        <br /><br />

        Esta política empezará a regir a partir del 01 de Enero de 2018. Los
        datos personales que sean almacenados, utilizados o transmitidos
        permanecerán en la base de datos de la compañía, con base en el criterio
        de temporalidad, durante el tiempo que sea necesario para los fines
        mencionados en esta política y para los cuales fueron recolectados.

        <br /><br />
        Cordialmente, AP3 CARNES S.A. NIT 900.262.893-9

        <br /><br />
        <strong>
          CONSENTIMIENTO EXPRESO PARA TRATAMIENTO DE DATOS PERSONALES
        </strong>
        <br /><br />

        De acuerdo con la Ley Estatutaria 1581 de 2012 de Protección de Datos y
        normas concordantes, autorizo, como Titular de los datos, que éstos sean
        incorporados en una base de datos responsabilidad de AP3 CARNES S.A.,
        siendo tratados con la finalidad de realizar gestión administrativa,
        fidelización de clientes, gestión de estadísticas internas, gestión de
        cobros y pagos, gestión de facturación, gestión económica, contable,
        fiscal y de marketing y demás. Asimismo, declaro haber sido informado de
        que puedo ejercer los derechos de acceso, corrección, supresión,
        revocación o reclamo por infracción sobre mis datos, mediante un escrito
        dirigido a AP3 CARNES S.A. a la dirección Carrera 40 36 Sur 30 (Envigado
        – Antioquia) o a la dirección de correo electrónico
        contabilidad1@ap3carnes.com, indicando en el asunto el derecho que desea
        ejercer. Es de carácter facultativo suministrar información que verse
        sobre Datos Sensibles, entendidos como aquellos que afectan la intimidad
        o generan algún tipo de discriminación o sobre menores de edad. A
        continuación mis datos, declarando que son verídicos y que pueden ser
        consultados en las bases de datos disponibles para esto:
      </p>
    </div>
    <Footer></Footer>
    <InfoStores></InfoStores>
    <Copyright></Copyright>
  </div>
</template>
<script>
// @ is an alias to /src
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";
import Subscribe from "../components/Subscribe.vue";
import Copyright from "../components/Copyright.vue";
import InfoStores from "../components/InfoStores.vue";

export default {
  name: "Politicas",
  components: {
    Menu,
    Footer,
    Subscribe,
    Copyright,
    InfoStores,
  },
  data: () => {
    return {};
  },
};
</script>