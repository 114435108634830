<template>
    <div class="copyrightAP3 backGold align-items-center d-flex py-3">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <p class="text-dark m-0">AP3 CARNES | 2021 © TODOS LOS DERECHOS RESERVADOS | <strong>POWERED BY CONKER</strong></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Copyright',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
