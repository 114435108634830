import Vue from 'vue'
import Vuex from 'vuex'
import WizardStore from './Wizard'
import ProductStore from './products'
import CartStore from './cart'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    wizard: WizardStore,
    products: ProductStore,
    cart: CartStore
  }
})
