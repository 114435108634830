<template>
    <div class="shipping">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="personalInfo text-left mb-5 pb-5">
                        <h4 class="h4Ap3 text-white text-uppercase mb-4">Información personal</h4>
                        <label for="" class="text-white">Nombre completo</label>
                        <b-form-input v-model="formData.name" placeholder="Ingresa tu nombre completo" class="mb-4"></b-form-input>
                        <label for="" class="text-white" type="email">Correo</label>
                        <b-form-input v-model="formData.email" placeholder="Ingresa tu correo" class="mb-4"></b-form-input>
                        <label for="" class="text-white">Celular</label>
                        <b-form-input v-model="formData.phone" placeholder="Ingresa tu número celular" class="mb-4"></b-form-input>
                    </div>
                    <div class="personalInfo text-left mt-5">
                        <h4 class="h4Ap3 text-white text-uppercase mb-4">Información de envío</h4>
                        <label for="" class="text-white">Ciudad</label>
                        <b-form-select v-model="formData.city" class="mb-3" placeholder="selecciona tu ciudad">
                            <b-form-select-option :value="null" selected>Please select an option</b-form-select-option>
                            <b-form-select-option value="medellin">Medellín</b-form-select-option>
                        </b-form-select>
                        <label for="" class="text-white">Barrio</label>
                        <b-form-input v-model="formData.neighborhood" placeholder="Ingresa el barrio" class="mb-4"></b-form-input>
                        <label for="" class="text-white">Dirección</label>
                        <b-form-input v-model="formData.address" placeholder="Ingresa tu dirección" class="mb-4"></b-form-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const {
  mapActions: stepMapActions,
  mapGetters: stepMapGetters,
} = createNamespacedHelpers("wizard")

export default {
  name: 'Shipping',
  data() {
      return {
          formData: {
              name: "",
              email: "",
              phone: "",
              city: "",
              neighborhood: "",
              address: "",
          }
      }
  },
  computed: {
    ...stepMapGetters(["cart"]),
  },
  methods: {
    ...stepMapActions(["SET_ORDER"]),
    createOrder() {
        this.SET_ORDER(this.formData)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
