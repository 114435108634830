<template>
  <div v-if="loading" class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: [ 'loading' ]
};
</script>
    

<style>
.spinner-border {
  color: #cebf7c !important;
}
</style>