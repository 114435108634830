<template>
  <div class="carousel-inner">
    <div class="carousel-item d-flex active">
      <div class="productCard col-12 mb-5">
        <a :href="`/${product._id}/compra-ya`">
          <div class="imgContainer">
            <img
              v-if="product.main_image"
              :src="product.main_image && typeof product.main_image === 'object' ? product.main_image.url: product.main_image "
              alt=""
              width="100%"
              class="d-inline mb-5 productImg"
            />
            <img
              v-else
              src="https://storage.googleapis.com/digammastudio/ap3/images/AP32.jpg"
              alt=""
              width="100%"
              class="d-inline mb-5 productImg"
            />
          </div>
          <div class="cardText text-left px-3 pt-3">
            <p class="productName mb-0">{{ product.name }}</p>
            <p class="productCategory mb-0">
              {{ product.categorias.map(item=>item.name).join(", ") }}
            </p>
            <p class="productPrice">{{ product.price }}</p>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: ["product"],
};
</script>

<style scoped>
</style>
