import axios from "axios"
import configService from "../../configs/config"
const token = window.localStorage.AUTH_TOKEN

const productService = {}
productService.getProducts = () => {
  return axios
    .get(`${configService.apiUrl}/productos`)
    .then(products => products.data)
    .catch(error => error)
}

productService.getProductsByQuery = (query) => {
  return axios
    .get(`${configService.apiUrl}/productos?${query}`)
    .then(products => products.data)
    .catch(error => error)
}

productService.getProduct = id => {
  return axios
    .get(`${configService.apiUrl}/productos/${id}`)
    .then(products => products.data)
    .catch(error => error)
}

//@deprecate
productService.getByCategory = category => {
  return axios
    .post(`${configService.apiUrl}/productos/byCategory`, category)
    .then(res => res.data)
    .catch(error => error)
}

//@deprecate
productService.createProduct = product => {
  return axios
    .post(`${configService.apiUrl}/productos/create`, product)
    .then(products => products.data)
    .catch(error => error)
}
//@deprecate
productService.updateProduct = (id, product) => {
  return axios
    .put(`${configService.apiUrl}/productos/${id}`, product)
    .then(products => products.data)
    .catch(error => error)
}
//@deprecate
productService.removeProduct = id => {
  return axios
    .delete(`${configService.apiUrl}/productos/${id}`)
    .then(products => products.data)
    .catch(error => error)
}

export default productService
