<template>
  <div class="superCategoria col-lg-3 col-6 mb-5">
    <a :href="`/categoria/${data.slug}`">
      <div class="imgContainer">
        <img
          v-if="data.image"
          :src="data.image && typeof data.image === 'object' ? data.image.url: data.image "
          :alt="data.name"
          width="100%"
          class="d-inline mb-5 productImg"
        />
        <img
          v-else
          src="../assets/img/AP31.jpg"
          :alt="data.name"
          width="100%"
          class="d-inline mb-5 productImg"
        />
      </div>
      <div class="cardText contCat px-3 pt-3">
        <p class="productName text-white">
          {{ data.name }}
        </p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "SuperCategoria",
  props: ["data"]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
