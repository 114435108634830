<template>
  <div class="contacto">
    <Menu></Menu>
    <div class="landingCont">
        <div class="container">
            <div class="row py-lg-5">
                <div class="col-lg-2"></div>
                <div class="col-lg-8 col-12 pb-5">
                    <img src="../assets/img/machine-icon.png" alt="" width="100" class="d-lg-inline mb-4 position-absolute machineIconLand d-none d-lg-block">
                    <img src="../assets/img/machine-icon.png" alt="" width="100" class="mb-4 position-absolute machineIconLandM d-lg-none">
                    <div class="redirectionMsgCont my-5 p-lg-5 p-4">
                        <img src="../assets/img/line-big-cta-ap3.png" width="100%" class="d-inline mb-2 mt-5" >
                        <h2 class="text-white h1Ap3 d-none d-lg-block text-uppercase mb-0">Tu pedido pronto<br>estará <strong class="fontGold">listo</strong></h2>
                        <h2 class="text-white h1Ap3M d-lg-none text-uppercase mb-0">Tu pedido pronto estará <strong class="fontGold">listo</strong></h2>
                        <img src="../assets/img/line-big-cta-ap3.png" width="100%" class="d-inline mb-2" >
                        <h4 class="font-weight-light text-white mt-4 d-none d-lg-block">Te estamos redireccionando a whatsapp donde un asesor tomará tu pedido, allí podrás realizar el proceso de pago y hacer seguimiento a tu pedido.</h4>
                        <p class="font-weight-light text-white mt-4 d-lg-none">Te estamos redireccionando a whatsapp donde un asesor tomará tu pedido, allí podrás realizar el proceso de pago y hacer seguimiento a tu pedido.</p>
                        <h3 class="fontGold text-uppercase h1Ap3 mt-5 d-none d-lg-block">¡Muchas gracias por<br>confiar en nosotros!</h3>
                        <h5 class="fontGold text-uppercase h1Ap3 mt-5 d-lg-none">¡Muchas gracias por confiar en nosotros!</h5>
                    </div>
                </div>
                <div class="col-lg-2"></div>
            </div>
        </div>
    </div>
    <Footer></Footer>
    <InfoStores></InfoStores>
    <Copyright></Copyright>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from '../components/Menu.vue'
import Footer from '../components/Footer.vue'
import Subscribe from '../components/Subscribe.vue'
import Copyright from '../components/Copyright.vue'
import InfoStores from "../components/InfoStores.vue";




export default {
  name: 'Landing',
  components: {
    Menu,
    Footer,
    Subscribe,
    Copyright,
    InfoStores,
  }
}
</script>
