<template>
    <div class="cartItem">
        <table class="table text-white table-borderless">
            <tbody  class="pt-5">
                <tr class="">
                    <th scope="row">
                        <div class="d-flex align-items-center">
                            <div class="cartImg mr-4">
                                <div class="cartImgCont rounded-circle">
                                    <img v-if="data.main_image" 
                                    :src="data.main_image && typeof data.main_image === 'object' ? data.main_image.url: data.main_image "
                                    alt="" width="80" class="cartImgProduct rounded-circle borderGold">
                                    <img v-else src="https://storage.googleapis.com/digammastudio/ap3/images/AP32.jpg" alt="" class="cartImgProduct rounded-circle borderGold" width="80">

                                                    <!-- <img v-else src="https://storage.googleapis.com/digammastudio/ap3/images/AP32.jpg" alt="" width="100%" class="d-inline mb-5 productImg"> -->

                                </div>
                            </div>
                            <div>
                                <p class="mb-0">{{data.name}}</p>
                            </div>
                        </div>
                    </th>
                    <td class="">
                        <p class="mb-0 pt-4">{{data.unit}}</p>
                    </td>
                </tr>
            </tbody>
        </table> 
    </div>
</template>

<script>
export default {
  name: 'CartItemSmall',
  props: ['data']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
