<template>
  <div class="cart">
    <Menu></Menu>
    <div class="container pb-5 cartView">
      <!-- <div class="row py-5">
        <div class="col-lg-8 col-12">
          <h3 class="h4Ap3 text-white text-uppercase text-left">
            Carrito de compras
          </h3>
        </div>
        <div class="col-4 text-right d-none d-lg-block">
          <div class="brandAP3 text-right">
            <img src="../assets/img/logo-ap3.png" alt="" width="60" />
          </div>
        </div>
      </div> -->
      <h4 class="h1Ap3 text-white text-uppercase mb-5 mt-5 text-left">
        Tu pedido
      </h4>
      <div class="row">
        <div class="col-12 text-left" v-for="product in cart">
          <CartItem :data="product" class="d-none d-lg-block"></CartItem>
          <CartItemSmall :data="product" class="d-lg-none"></CartItemSmall>
        </div>
      </div>
      <div class="row my-5">
          <div
            v-if="carritoVacio()"
            class="row py-3 px-3 mb-5 rounded-lg col-lg-12"
          >
            <div class="col-11 pl-4">
              <span class="material-icons warningIcon pt-3">
                error_outline
              </span>
              <p class="text-center text-white mt-3 font-weight-bolder">
                El carrito esta 
                <span class="fontGold">vacío</span>
              </p>
            </div>
          </div>
        <div class="col-lg-6"></div>
        <div class="col-lg-6 col-12">
          <div
            v-if="menos50()"
            class="row pedidoMinimo py-3 px-3 mb-5 rounded-lg"
          >
            <div class="col-1 text-left">
              <!-- <img src="../assets/img/pesa-ap3.png" alt="" width="100%" class="d-inline"> -->
              <span class="material-icons warningIcon pt-3">
                error_outline
              </span>
            </div>
            <div class="col-11 pl-4">
              <p class="text-left text-white mt-3 font-weight-bolder">
                El pedido mínimo requerido es de
                <span class="fontGold">$50.000</span>
              </p>
              <p class="text-left text-white mt-3 warningText">
                Agrega más productos a tu carrito de compras para continuar con
                tu orden.
              </p>
            </div>
          </div>
          <div
            class="row totalCart py-3 px-3 mb-5 d-flex align-items-center rounded-lg"
          >
            <div class="col-6">
              <p class="text-left text-white mt-3">Total aproximado</p>
            </div>
            <div class="col-6">
              <p class="text-right text-white mt-3">
                <strong>{{ total | currency }}</strong>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-12 p-lg-0">
              <!-- <a
                href="/comprar-ya"
                class="seguir-comprando btn btn-outline-secondary primaryBtn d-flex align-items-center fullWithAp3 mb-3"
                type="button"
                id="button-addon2"
              >
                <i class="material-icons chevronAp3">chevron_left</i>SEGUIR
                COMPRANDO
              </a> -->
              <button
                class="btn btn-outline-secondary primaryBtn d-flex align-items-center"
                type="button"
                id="button-addon2"
                @click="redirectBwd()"
              >
                <i class="material-icons chevronAp3">chevron_left</i>
                SEGUIR COMPRANDO
              </button>
            </div>
            <div class="col-1"></div>
            <div class="col-lg-5 col-12 p-lg-0">
              <!-- =================================DESHABILITAR BOTON ================================================-->
              <button
                v-if="menos50()"
                class="btn btn-outline-secondary successBtn d-flex align-items-center float-right fullWithAp3 disabled"
                type="button"
                id="button-addon2"
              >
                REALIZAR PEDIDO
                <i class="material-icons chevronAp3">chevron_right</i>
              </button>
              <button
                v-else
                class="btn btn-outline-secondary successBtn d-flex align-items-center text-center float-right mb-5 fullWithAp3"
                type="button"
                id="button-addon2"
                @click="redirectFwd()"
              >
                REALIZAR PEDIDO
                <i class="material-icons chevronAp3">chevron_right</i>
              </button>
              <!-- ============================================================================ -->
              <!-- <a
                v-if="menos50()"
                href=""
                class="btn btn-outline-secondary successBtn d-flex align-items-center text-center float-right mb-5 fullWithAp3 disabled"
                type="button"
                id="button-addon2"
              >
                REALIZAR PEDIDO
                <i class="material-icons chevronAp3">chevron_right</i>
              </a>
              <a
                v-else
                href="/realizar-pedido"
                class="btn btn-outline-secondary successBtn d-flex align-items-center text-center float-right mb-5 fullWithAp3"
                type="button"
                id="button-addon2"
              >
                REALIZAR PEDIDO
                <i class="material-icons chevronAp3">chevron_right</i>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <InfoStores></InfoStores>
    <Copyright></Copyright>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "../components/Menu.vue";
import Footer from "../components/Footer.vue";
import Subscribe from "../components/Subscribe.vue";
import Copyright from "../components/Copyright.vue";
import WizardComp from "../components/WizardComp.vue";
import InfoStores from "../components/InfoStores.vue";
import CartItem from "../components/CartItem.vue";
import CartItemSmall from "../components/CartItemSmall.vue";
import { createNamespacedHelpers } from "vuex";
const {
  mapActions: cartMapActions,
  mapGetters: cartMapGetters,
} = createNamespacedHelpers("cart");

export default {
  name: "Cart",
  components: {
    Menu,
    Footer,
    Subscribe,
    Copyright,
    CartItem,
    CartItemSmall,
    InfoStores,
  },
  data() {
    return {
      shipping: 0,
    };
  },
  computed: {
    ...cartMapGetters(["cart", "subtotal", "total"]),
  },
  mounted() {
    this.SET_SUBTOTAL();
    this.SET_TOTAL(this.shipping);
  },
  methods: {
    ...cartMapActions(["SET_CART", "SET_TOTAL", "SET_SUBTOTAL", "CLEAR_CART"]),
    clearCart(product) {
      this.CLEAR_CART(product);
    },
    menos50() {
      if (this.total < 50000) {
        return true;
      }
    },
    carritoVacio() {
      if (this.total == 0) {
        return true;
      }
    },
    redirectFwd() {
      this.$router.push("/realizar-pedido");
    },
    redirectBwd() {
      this.$router.push("/productos");
    }
  },
};
</script>
