<template>
  <div class="footerAP3 pb-5 pt-5">
    <!-- desktop version -->
    <div class="container d-none d-lg-block">
      <div class="row text-left">
        <div class="col-3">
          <div class="row">
            <div class="col-6">
              <img
                src="../assets/img/logo-footer.png"
                alt=""
                width="100"
                class="d-inline"
              />
            </div>
            <div class="col-6">
              <p class="footerTitle mb-1">SOBRE<br />AP3- CARNES</p>
              <ul>
                <li class="mt-3">
                  <a href="/about" class="footerLink text-white"
                    >- Sobre Nosotros</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-3">
          <p class="footerTitle mb-1">NUESTRAS<br />POLÍTICAS</p>
          <ul>
            <li class="mt-3">
              <a href="/politicas" class="footerLink text-white"
                >- Política de tratamiento de datos</a
              >
            </li>
          </ul>
        </div>
        <div class="col-3">
          <p class="footerTitle mb-1">ENCUÉNTRANOS <br />FACILMENTE</p>
          <p href="" class="footerLink text-white mt-3">
            Tenemos sedes en la ciudad de envigado, itagui (Mayorista) y la estrella (Guayabal).
          </p>
        </div>
        <div class="col-3">
          <p class="footerTitle mb-1">COMUNÍCATE <br />CON NOSOTROS</p>
          <div
            class="carrito d-flex align-items-center py-2 justify-content-center my-3"
          >
            <a
              href="https://api.whatsapp.com/send?phone=573206158178&text=Hola, quisiera comunicarme con un asesor"
              target="_blank"
            >
              <img
                src="../assets/img/wapp.svg"
                alt=""
                width="20"
                class="d-inline mr-2"
              />
              <p class="d-inline font-weight-bolder text-white mb-0">
                WHATSAPP
              </p>
            </a>
          </div>
          <small href="" class="text-white mt-5">
            TELEFONO: <strong>331 29 91 - 322 17 91</strong>
            WHATSAPP: <strong>320 615 81 78</strong>
          </small>
        </div>
      </div>
    </div>
    <!-- desktop version -->

    <!-- mobile version -->
    <div class="container footerAP3M d-lg-none">
      <div class="row text-left">
        <div class="col-12">
          <div class="row">
            <div class="col-12 text-center">
              <img
                src="../assets/img/logo-footer.png"
                alt=""
                width="100"
                class="d-inline"
              />
            </div>
            <div class="col-12">
              <div
                class="carrito d-flex align-items-center py-2 justify-content-center mt-5"
              >
                <a
                  href="https://api.whatsapp.com/send?phone=573206158178&text=Hola, quisiera comunicarme con un asesor"
                  target="_blank"
                >
                  <img
                    src="../assets/img/wapp.svg"
                    alt=""
                    width="20"
                    class="d-inline mr-2"
                  />
                  <p href="" class="d-inline font-weight-bolder fontGold mb-0">
                    WHATSAPP
                  </p>
                </a>
              </div>
            </div>
            <div class="col-12 text-center">
              <h3 class="h3Ap3 mb-1 mt-5 fontGold">ENCUÉNTRANOS FACILMENTE</h3>
              <p href="" class="footerTextM text-white mt-3">
                Tenemos sedes en la ciudad de envigado, itagui (Mayorista) y la estrella (Guayabal).
              </p>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-12 text-center">
              <p class="footerTitleM mb-1 mt-5">SOBRE AP3- CARNES</p>
              <ul>
                <li class="mt-3">
                  <a href="" class="footerTextM text-white">- Sobre Nosotros</a>
                </li>
                <li>
                  <a href="" class="footerTextM text-white"
                    >- EL LEGADO FAMILIAR</a
                  >
                </li>
              </ul>
              <p class="footerTitleM mb-1 mt-5">NUESTRAS POLÍTICAS</p>
              <ul>
                <li class="mt-3">
                  <a href="/politicas" class="footerTextM text-white"
                    >- Política de tratamiento de datos</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- mobile version -->
  </div>
</template>

<script>
import SeoService from "@/services/Seo"

export default {
  name: "Footer",
  data() {
    return {
      configuration: "",
    }
  },
  async mounted() {
    this.getSeo()
  },
  methods: {
    async getSeo() {
      const seo = await SeoService.getSeo()
      this.configuration = seo;

      if(this.configuration.analytics){
        document.body.insertAdjacentHTML('beforeend', this.configuration.analytics);
      }


      return this.configuration
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
