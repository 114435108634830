<template>
  <div class="row sidebar">
    <div class="col-12">
      <h4 class="h4Ap3 text-white text-uppercase mb-4 text-left">
        Categorías
      </h4>
    </div>
    <div class="col-12 text-left categories">
      <a v-if="(this.category.categorias && this.category.categorias.length === 0)|| !this.category.categorias" style="cursor: pointer;" href="javascript:history.back()"  >
        <p class="py-3 px-4 catItem mb-1 text-uppercase">
          <strong>Atras</strong>
        </p>
      </a>
      <a style="cursor: pointer;" :href="`./${String(category.name).toLowerCase()}`"  v-for="category in this.category.categorias">
        <p class="py-3 px-4 catItem mb-1 text-uppercase">
          <strong>{{ category.name }}</strong>
        </p>
      </a>
    </div>
  </div>
</template>

<script>
import categoryService from "@/services/Categories"
import ProductService from '../services/Products'
import { createNamespacedHelpers } from "vuex"
const {
  mapActions: productsMapActions,
  mapGetters: productsMapGetters,
} = createNamespacedHelpers("products")

export default {
  name: "Sidebar",
  props: ["data"],
  data() {
    return {
      category: []
    };
  },
  mounted() {
    
    this.category = this.data;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
