<template>
  <div class="productos">
    <Menu></Menu>
    <div class="container mb-5 productsPage">
      <div class="row">
        <div class="col-12">
          <div class="row text-center mb-5">
            <div class="col-12">
              <img
                src="../assets/img/pesa-ap3.png"
                alt=""
                width="150"
                class="d-inline mt-5"
              />
            </div>
          </div>
          <img
            src="../assets/img/line-big-cta-ap3.png"
            alt=""
            width="70%"
            class="d-inline mb-2"
          />
          <h2 class="text-white h1Ap3 mb-3 d-none d-lg-block">
            TENEMOS UNA SELECCIÓN<br />
            <strong>DE LAS MEJORES CARNES PARA TI</strong>
          </h2>
          <h2 class="text-white h1Ap3M mb-3 d-lg-none">
            TENEMOS UNA SELECCIÓN<br />
            <strong>DE LAS MEJORES CARNES PARA TI</strong>
          </h2>
          <img
            src="../assets/img/line-big-cta-ap3.png"
            alt=""
            width="70%"
            class="d-inline mb-5"
          />
          <div class="row mt-5">
            <Loader
              v-if="loading"
              :loading="loading"
              style="margin: 0 auto;"
            ></Loader>
            <SuperCategoria
              v-else
              v-for="category in categories"
              :data="category"
            ></SuperCategoria>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <InfoStores></InfoStores>
    <Copyright></Copyright>
  </div>
</template>

<script>
  // @ is an alias to /src
  import Menu from '../components/Menu.vue'
  import Footer from '../components/Footer.vue'
  import Subscribe from '../components/Subscribe.vue'
  import Copyright from '../components/Copyright.vue'
  import WizardComp from '../components/WizardComp.vue'
  import SuperCategoria from '../components/SuperCategoria.vue'
  import categoryService from '@/services/Categories'
  import Loader from '../components/Loader.vue'
  import InfoStores from "../components/InfoStores.vue";
  import qs from 'qs'

  export default {
    name: 'Productos',
    components: {
      Menu,
      Footer,
      Subscribe,
      Copyright,
      WizardComp,
      SuperCategoria,
      Loader,
      InfoStores,
    },
    data() {
      return {
        categories: '',
        loading: true,
      }
    },
    mounted() {
      this.getCategories()
      this.addMeta()
    },
    methods: {
      async getCategories() {
        const query = qs.stringify({})
        const categories = await categoryService.getCategories(query)

        this.categories = categories.filter(
          (category) => !category.parentCategory
        )
        this.loading = false
        return this.categories
      },
      addMeta() {
        window.document.title = 'Productos | AP3'
        const metaDescription = document.createElement('meta')
        metaDescription.setAttribute('name', 'description')
        metaDescription.setAttribute('content', 'Página de producto')
        window.document
          .getElementsByTagName('head')[0]
          .appendChild(metaDescription)
      },
    },
  }
</script>
