<template>
    <div class="productCard col-lg-4 col-6 mb-5">
        <a :href="`/${data._id}/compra-ya`">
            <div class="imgContainer">
                <img v-if="data.main_image" 
                :src="data.main_image && typeof data.main_image === 'object' ? data.main_image.url: data.main_image "
                alt="" width="100%" class="d-inline mb-5 productImg">
                <img v-else src="https://storage.googleapis.com/digammastudio/ap3/images/AP32.jpg" alt="" width="100%" class="d-inline mb-5 productImg">
            </div>
            <div class="cardText text-left px-3 pt-3">
                <p class="productCategory mb-0"><span v-for="category in data.categories">{{category}} </span></p>
                <p class="productName mb-0">{{data.name}}</p>
                <p class="productPrice">{{data.price | currency}}  {{String( data.unitType).toUpperCase()}}</p>
            </div>
        </a>
        
    </div>
</template>

<script>
export default {
  name: 'ProductCard',
  props: ['data']
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
