<template>
    <div class="cartResume">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h4 class="h4Ap3 text-white text-uppercase mb-4 text-left">Tu pedido</h4>
                    <div class="imgContainer mb-5">
                        <img src="../assets/img/carne-test.png" alt="" width="100%" class="d-inline mb-5 productImg borderGold">
                    </div>
                    <div class="text-left fontLight">
                        <p class="productCategory mb-0">FINAS</p>
                        <h4 class="fontGold mb-3 font-weight-bolder">Solomito</h4>
                        <p class="mb-0">Cantidad: <strong>{{cart.unit}}</strong></p>
                        <p class="mb-0">Porcionado: <strong>{{cart.tipoPorcion}}</strong></p>
                        <p class="mb-0">Empaque: <strong>{{cart.tipoEmpaque[0].name}}</strong></p>
                        <p class="mb-5">Especificaciones: <strong>{{cart.specifications}}</strong></p>
                        <p class="productCategory mb-0 fontLight">Valor aproximado</p>
                        <h3 class="productPrice2 mb-5">{{cart.total | currency}}</h3>
                    </div>
                    <button class="btn btn-outline-secondary primaryBtn fullWithAp3" type="button" id="button-addon2">REALIZAR PEDIDO</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const {
  mapActions: stepMapActions,
  mapGetters: stepMapGetters,
} = createNamespacedHelpers("wizard")

export default {
  name: 'CartResume',
  computed: {
    ...stepMapGetters(["cart"]),
  },
  methods: {
    ...stepMapActions(["SET_CART"]),
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
