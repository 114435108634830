import axios from 'axios'

const token = process.env.VUE_APP_TOKEN
const axiosInstance = axios.create({})

// axios.interceptors.request.use(function(config) {
//   if(token) {
//       config.headers.Authorization = `Bearer ${token}`
//   }
//   return config;
// }, function(err) {
//   return Promise.reject(err);
// });

export default axiosInstance