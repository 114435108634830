import axios from "axios";
import configService from "../../configs/config";
import _html from '../../assets/temeplates/ordenes'

const orderService = {};
orderService.getOrders = () => {
  return axios
    .get(`${configService.apiUrl}/ordenes`)
    .then(orders => orders.data)
    .catch(error => error)
};

orderService.sendEmailOrder = (order) => {

  return axios
    .post("https://ap3-mails.herokuapp.com", order)
    .then(r => {
      return true;
    })
    .catch(r => {
      return true;
    });


};

orderService.getOrder = id => {
  return axios
    .get(`${configService.apiUrl}/ordenes/${id}`)
    .then(order => order.data.order)
    .catch(error => error);
};

orderService.createOrder = (order) => {
  return axios
    .post(`${configService.apiUrl}/ordenes`, order)
    .then(order => {
      return order
    })
    .catch(error => error);
};

orderService.createProductsOrder = (orderProduct) => {
  return axios
    .post(`${configService.apiUrl}/carts`, orderProduct)
    .then(products => {
      return products
    })
    .catch(error => error);
};

orderService.updateOrder = (quoteId, order) => {
  return axios
    .put(`${configService.apiUrl}/ordenes/${quoteId}`, order)
    .then(order => order)
    .catch(error => error);
};
//deprecated
orderService.removeOrder = id => {
  return axios
    .delete(`${configService.apiUrl}/ordenes/${id}`)
    .then(order => order.data)
    .catch(error => error);
};

export default orderService;

