<template>
  <div class="home">
    <Menu></Menu>
    <div class="container mb-5 checkoutView">
      <div class="row">
        <div class="col-lg-4 col-12 cartCont p-4 rounded-lg d-lg-none">
          <div class="cartResume">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h4 class="h4Ap3 text-white text-uppercase mb-4 text-left">
                    Tu pedido
                  </h4>
                  <div class="row">
                    <div class="col-12 p-0" v-for="product in cart">
                      <CartItemSmall :data="product"></CartItemSmall>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12">
                      <div
                        class="row d-flex align-items-center shippingTimeCont py-4 rounded-lg"
                      >
                        <div class="col-2">
                          <span class="material-icons clokIcon">
                            access_time
                          </span>
                        </div>
                        <div class="col-10">
                          <p
                            class="text-left text-white font-weight-bolder mb-1 shippingTime"
                          >
                            Tiempo aproximado de entrega
                          </p>
                          <p class="text-left text-white shippingTimeDes mb-0">
                            Un día hábil
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-3">
                      <div
                        class="row d-flex align-items-center shippingTimeCont py-4 rounded-lg"
                      >
                        <div class="col-2">
                          <span class="material-icons clokIcon">
                            feedback
                          </span>
                        </div>
                        <div class="col-10">
                          <p
                            class="text-left text-white font-weight-bolder mb-1 shippingTime"
                          >
                            Importante
                          </p>
                          <p class="text-left text-white shippingTimeDes mb-0">
                            Tu pedido será tomado por uno de nuestros asesores, este te informará el precio final de tu pedido y el tiempo de entrega.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-left fontLight mt-5 row">
                    <div class="col-12 mb-5">
                      <div class="row d-flex align-items-center">
                        <div class="col-7">
                          <h6 class="mb-0 fontGold font-weight-bolder">
                            Total aproximado
                          </h6>
                        </div>
                        <div class="col-5">
                          <h3 class="productPrice2 text-right">
                            {{ total | currency }}
                          </h3>
                        </div>
                      </div>
                      <div class="row d-flex align-items-center mt-4 mb-0">
                        <div class="col-12">
                          <small class="text-left mb-0">Recuerde que el tope mínimo del pedido y el costo del envío será cuantificado según la ubicación de entrega, esta información será suministrada por el asesor.</small>
                        </div>
                      </div> 
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-2">
                      <span class="material-icons arrowIcon">
                        arrow_downward
                      </span>
                    </div>
                    <div class="col-10">
                      <p class="text-left text-white">
                        Ingresa tu información de envío
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-12 shippingCont mt-5 p-4 rounded-lg mr-5">
          <div class="shipping mt-5">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="personalInfo text-left mb-5 pb-5">
                    <h4 class="h4Ap3 text-white text-uppercase mb-4">
                      Información personal
                    </h4>
                    <label for="" class="text-white mt-4">Nombre completo</label>
                    <b-form-input
                      v-model="name"
                      :class="{'is-invalid': submitted && $v.name.$error }"
                      placeholder="Ingresa tu nombre completo"
                    ></b-form-input>
                     <div
                      v-if="submitted && !$v.name.required"
                      class="invalid-feedback"
                    >
                      Nombre completo requerido
                    </div>
                    <label for="" class="text-white mt-4" type="email">Correo</label>
                    <b-form-input
                      v-model="email"
                      :class="{'is-invalid': submitted && $v.email.$error }"
                      placeholder="Ingresa tu correo"
                    ></b-form-input>
                     <div
                      v-if="submitted && !$v.email.required"
                      class="invalid-feedback"
                    >
                      Correo requerido
                    </div>
                    <label for="" class="text-white mt-4">Celular</label>
                    <b-form-input
                      v-model="phone"
                      :class="{'is-invalid': submitted && $v.phone.$error }"
                      placeholder="Ingresa tu número celular"
                    ></b-form-input>
                     <div
                      v-if="submitted && !$v.phone.required"
                      class="invalid-feedback"
                    >
                      Celular requerido
                    </div>
                  </div>
                  <div class="personalInfo text-left mt-5">
                    <h4 class="h4Ap3 text-white text-uppercase mb-4">
                      Información de envío
                    </h4>
                    <label for="" class="text-white">Ciudad</label>
                    <b-form-input
                      v-model="city"
                      class="mb-3"
                      placeholder="Ingresa tu ciudad"
                      :class="{'is-invalid': submitted && $v.city.$error }"
                    >                      
                    </b-form-input>
                    <div
                      v-if="submitted && !$v.city.required"
                      class="invalid-feedback"
                    >
                      Ciudad es requerida
                    </div>
                    <p class="text-left text-white shippingTimeDes mb-4">Sujeto a disponibilidad <i class="el-icon-info"></i></p>
                    <label for="" class="text-white">Barrio</label>
                    <b-form-input
                      v-model="neighborhood"
                      placeholder="Ingresa el barrio"
                      :class="{'is-invalid': submitted && $v.neighborhood.$error }"
                    ></b-form-input>
                     <div
                      v-if="submitted && !$v.neighborhood.required"
                      class="invalid-feedback"
                    >
                      Barrio es requerido
                    </div>
                    <label for="" class="text-white mt-4">Dirección</label>
                    <b-form-input
                      v-model="address"
                      placeholder="Ingresa tu dirección"
                      :class="{'is-invalid': submitted && $v.address.$error }"
                    ></b-form-input>
                     <div
                      v-if="submitted && !$v.address.required"
                      class="invalid-feedback"
                    >
                      La dirección es requerida
                    </div>
                    <label for="" class="text-white mt-4">Punto de referencia</label>
                    <b-form-input
                      v-model="reference"
                      placeholder="Unidad, Apartamento, Oficina, etc"
                      :class="{'is-invalid': submitted && $v.reference.$error }"
                    ></b-form-input>
                    <div
                      v-if="submitted && !$v.reference.required"
                      class="invalid-feedback"
                    >
                      Punto de referencia requerido
                    </div>
                    <div class="row">
                      <div class="col-lg-5 col-12 d-lg-block d-none">
                        <a
                          href="/carrito-de-compras"
                          class="seguir-comprando btn btn-outline-secondary primaryBtn d-flex align-items-center fullWithAp3 my-3"
                          type="button"
                          id="button-addon2"
                        >
                          <i class="material-icons chevronAp3">chevron_left</i
                          >REGRESAR
                        </a>
                      </div>
                      <div class="col-lg-7 col-12">
                        <a
                          @click="createOrder()"
                          class="btn btn-outline-secondary successBtn fullWithAp3 my-3"
                          id="button-addon2"
                        >
                          REALIZAR PEDIDO
                        </a>
                      </div>
                      <div class="col-lg-5 col-12 d-lg-none">
                        <a
                          href="/carrito-de-compras"
                          class="seguir-comprando btn btn-outline-secondary primaryBtn d-flex align-items-center fullWithAp3 my-3"
                          type="button"
                          id="button-addon2"
                        >
                          <i class="material-icons chevronAp3">chevron_left</i
                          >REGRESAR
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-4 col-12 cartCont mt-5 p-4 rounded-lg d-none d-lg-block"
        >
          <div class="cartResume mt-5">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <h4 class="h4Ap3 text-white text-uppercase mb-4 text-left">
                    Tu pedido
                  </h4>
                  <div class="row">
                    <div class="col-12 p-0" v-for="product in cart">
                      <CartItemSmall :data="product"></CartItemSmall>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-12">
                      <div
                        class="row d-flex align-items-center shippingTimeCont py-4 rounded-lg"
                      >
                        <div class="col-2">
                          <span class="material-icons clokIcon">
                            access_time
                          </span>
                        </div>
                        <div class="col-10">
                          <p
                            class="text-left text-white font-weight-bolder mb-1 shippingTime"
                          >
                            Tiempo aproximado de entrega
                          </p>
                          <p class="text-left text-white shippingTimeDes mb-0">
                            Un día hábil
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 mt-3">
                      <div
                        class="row d-flex align-items-center shippingTimeCont py-4 rounded-lg"
                      >
                        <div class="col-2">
                          <span class="material-icons clokIcon">
                            feedback
                          </span>
                        </div>
                        <div class="col-10">
                          <p
                            class="text-left text-white font-weight-bolder mb-1 shippingTime"
                          >
                            Importante
                          </p>
                          <p class="text-left text-white shippingTimeDes mb-0">
                            Tu pedido será tomado por uno de nuestros asesores, este te informará el precio final de tu pedido y el tiempo de entrega.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-left fontLight mt-5 row">
                    <div class="col-12 mb-5">
                      <div class="row d-flex align-items-center">
                        <div class="col-7">
                          <h6 class="mb-0 fontGold font-weight-bolder">
                            Total aproximado
                          </h6>
                        </div>
                        <div class="col-5">
                          <h3 class="productPrice2 text-right">
                            {{ total | currency }}
                          </h3>
                        </div>
                      </div>
                      <div class="row d-flex align-items-center mt-4 mb-0">
                        <div class="col-12">
                          <small class="text-left mb-0">Recuerde que el tope mínimo del pedido y el costo del envío será cuantificado según la ubicación de entrega, esta información será suministrada por el asesor.</small>
                        </div>
                      </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <InfoStores></InfoStores>
    <Copyright></Copyright>
  </div>
</template>

<script>
// @ is an alias to /src
import Menu from "../components/Menu.vue"
import Footer from "../components/Footer.vue"
import Subscribe from "../components/Subscribe.vue"
import Copyright from "../components/Copyright.vue"
import Shipping from "../components/Shipping.vue"
import CartResume from "../components/CartResume.vue"
import CartItemSmall from "../components/CartItemSmall.vue"
import orderService from "../services/Orders"
import InfoStores from "../components/InfoStores.vue";
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

const {
  mapActions: stepMapActions,
  mapGetters: stepMapGetters,
} = createNamespacedHelpers("wizard");
const {
  mapActions: cartMapActions,
  mapGetters: cartMapGetters,
} = createNamespacedHelpers("cart");

export default {
  name: "RealizarPedido",
  components: {
    Menu,
    Footer,
    Subscribe,
    Copyright,
    Shipping,
    CartResume,
    CartItemSmall,
    InfoStores,
  },
  mixins: [validationMixin],
  data() {
    return {
      submitted: false,
      name: "",
      email: "",
      phone: "",
      city: "",
      neighborhood: "",
      address: "",
      reference: "",
      productos: ""
    };
  },
  validations: {
    name: { required },
    email: { required },
    phone: { required },
    city: { required },
    neighborhood: { required },
    address: { required },
    reference: { required },
  },
  computed: {
    ...cartMapGetters(["cart", "subtotal", "total"]),
  },
  mounted() {
    const haveCart = this.cart;
    if (!haveCart) this.$router.push("/productos");
    this.SET_SUBTOTAL();
    this.SET_TOTAL(this.shipping);
  },
  methods: {
    ...stepMapActions(["SET_ORDER"]),
    ...cartMapActions(["SET_CART", "SET_TOTAL", "SET_SUBTOTAL", "CLEAR_CART"]),
    createOrder() {
      
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const order = {
        status: "pendiente",
        total: this.total,
        Productos: this.cart.map(item=>this.buildProduct(item)),
        BillingInfo: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          address: this.address,
          city: this.city,
          neighborhood: this.neighborhood,
          reference: this.reference,
          "__component": "select-options.billing-info"
        }
      };

      this.SET_ORDER(order);
    
      orderService.createOrder(order).then((res) => {
        
        let _order = {...order, idOrden: res.data.id}

        orderService.sendEmailOrder(_order);

        const whatsapp = `https://wa.me/573206158178?text=¡Hola! mi nombre es ${this.name}, esta es mi orden: ${res.data.id}, teléfon: ${order.BillingInfo.email}`;

        window.open(whatsapp);

        localStorage.removeItem("order");
        localStorage.removeItem("cart");
        setTimeout(() => {
          this.$router.push('/redireccion')
        }, 1000);
      })
      
    },
    buildProduct(cart){
      let tipoEmpaqueName= cart.tipoEmpaque.reduce((a,b)=>b.name,"");
      let tipoEmpaqueCost= cart.tipoEmpaque.reduce((a,b)=>b.cost,0);
      if (!cart.main_image) {
        cart.main_image = {};
      }
      if (!cart.main_image.url) {
        cart.main_image.url = "https://storage.googleapis.com/digammastudio/ap3/images/AP32.jpg";
      }
      return {
        "product": cart.id,
        "name": cart.name,
        "price": cart.price,
        "url": cart.main_image.url,
        "unit": cart.unit,
        "porcion": cart.porcion,
        "tipoPorcion": cart.tipoPorcion,
        "tipoEmpaqueName": tipoEmpaqueName,
        "tipoEmpaqueCost": tipoEmpaqueCost,
        "specifications": cart.specifications,
        "total": cart.total,
        "unitType": cart.unitType,
        "resume": `${cart.name}\nCantidad: ${cart.unit} ${cart.unitType}\nPorcionado: ${cart.porcion}${cart.tipoPorcion?` - ${cart.tipoPorcion}`:''}\nEmpaque: ${tipoEmpaqueName}\nEspecificaciones: ${cart.specifications}\nValor aproximado: ${cart.total}`,
        "__component": "select-options.product"
      }
    }
  },
};
</script>

<style>
  .successBtn {
     -moz-background-color: blue;
    -ms-background-color: blue;
    -o-background-color: blue;
    -webkit-background-color: blue;
    background-color: blue;
  } 
</style>