'use strict'

const cart = JSON.parse(localStorage.getItem("cart")) || []
export default {
  namespaced: true,
  state: {
    step: 1,
    cart: cart,
    disableBefore: true,
    disableNext: false,
    showPorcion: false,
    order: {}
  },
  actions: {
    async SET_STEP({commit}, action) {
      commit('SET_STEP', action)
    },
    async SET_CART({commit}, product) {
      commit('SET_CART', product)
    },
    async SET_ORDER({commit}, order) {
      commit('SET_ORDER', order)
    }
  },
  mutations: {
    SET_STEP(state, action) {
      if(action === 'next' && state.step < 5) {
        state.step++
        state.disableBefore = false
        state.showPorcion = false
        if(state.step == 5) state.disableNext = true
      }
      if(action === 'before' && state.step > 1) {
        state.step--
        state.disableNext = false
        state.showPorcion = false
        if(state.step == 1) state.disableBefore = true 
      }
      if(action === 'porcion') {
        state.showPorcion = true
      }else{
        state.showPorcion = false
      }
    },
    SET_CART(state, product) {
      state.cart.push(product)
      localStorage.setItem("cart", JSON.stringify(state.cart))
    },
    async SET_ORDER(state, order) {
      localStorage.setItem("order", JSON.stringify(order))
    }
  },
  getters: {
    step: state => state.step,
    disableBefore: state => state.disableBefore,
    disableNext: state => state.disableNext,
    showPorcion: state => state.showPorcion,
  },
}