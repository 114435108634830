<template>
    <div class="tradicionAP3 backGold align-items-center d-flex py-3">
        <!-- desktop version -->
        <div class="container d-none d-lg-block">
            <div class="row py-2">
                <div class="col-2"></div>
                <div class="col-12 col-lg-8">
                    <h5 class="text-dark m-0 h5Ap3 border border-dark py-3">UNA TRADICIÓN DE 4 GENERACIONES</h5>
                </div>
                <div class="col-2"></div>
            </div>
        </div>
        <!-- end desktop version -->

        <!-- mobile version -->
        <div class="container d-lg-none">
            <div class="row py-2">
                <div class="col-12">
                    <h5 class="text-dark m-0 h5Ap3M">UNA TRADICIÓN DE 4 GENERACIONES</h5>
                </div>
            </div>
        </div>
        <!-- end mobile version -->
    </div>
</template>

<script>
export default {
  name: 'TradicionBadge',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
