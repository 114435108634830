<template>
    <div class="cartItem">
        <div class="row shippingCont rounded-lg py-3 text-white mb-3">
            <div class="col-4">
                <div class="d-flex align-items-center">
                    <div class="cartImg mr-4">
                        <div class="cartImgCont rounded-circle">
                            <!-- <img src="../assets/img/carne-test.png" alt="" class="cartImgProduct rounded-circle borderGold" width="80"> -->
                            <img v-if="data.main_image" 
                            :src="data.main_image && typeof data.main_image === 'object' ? data.main_image.url: data.main_image "
                            alt="" width="80" class="cartImgProduct rounded-circle borderGold">
                            <img v-else src="https://storage.googleapis.com/digammastudio/ap3/images/AP32.jpg" alt="" width="80" class="cartImgProduct rounded-circle borderGold">

                        </div>
                    </div>
                    <div>
                        <p class="mb-0 font-weight-bolder text-capitalize">{{data.name}}</p>
                    </div>
                </div>
            </div>
            <div class="col-1">
                <p class="mb-0 pt-4">{{data.unit}}</p>
            </div>
            <div class="col-2">
                <p class="mb-0 pt-4 text-capitalize">{{data.tipoPorcion || 'Entero'}}</p>
            </div>
            <div class="col-2">
                <p class="mb-0 pt-4 text-capitalize">{{data.tipoEmpaque[0].name}}</p>
            </div>
            <div class="col-2">
                <p class="mb-0 pt-4 fontLibre">{{data.total | currency}}</p>
            </div>
            <div class="col-1">
                <!--=============== ACTUALIZAR TOTAL ==================================================== -->
                <!-- clearCart() y total en CartItem y Cart -->
                <a @click="clearCart(data)" style="cursor: pointer;">
                    <span class="material-icons pt-4 deleteIcon">
                        close
                    </span>
                </a>
            </div>
        </div> 
    </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const {
  mapActions: cartMapActions,
  mapGetters: cartMapGetters,
} = createNamespacedHelpers("cart")

export default {
  name: 'CartItem',
  props: ['data'],
  computed: {
    ...cartMapGetters(["cart", "total"]),
  },
  methods: {
    ...cartMapActions(["CLEAR_CART", "SET_TOTAL"]),
    clearCart(product) {
      this.CLEAR_CART(product)
      this.SET_TOTAL(0)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
